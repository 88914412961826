import axios from 'axios';
import { Chart } from "react-google-charts";
import React, { useEffect, useState } from 'react';

//Project components
import Nav from '../components/Nav';
import Alert from "../components/Alert";
import Sidebar from '../components/Sidebar';
import {
    TOKEN_KEY,
    JOB_REPORT,
    REPORT_SUMMARY,
    REVENUE_REPORT,
} from '../components/Constants';

function Dashboard() {

    const [alert, setAlert] = useState("");
    const [summaryReport, setSummaryReport] = useState({});
    const [dailyRevenueReport, setDailyRevenueReport] = useState("");
    const [yearlyRevenueReport, setYearlyRevenueReport] = useState("");
    const [monthlyRevenueReport, setMonthlyRevenueReport] = useState("");
    const [currentRevenueGraph, setCurrentRevenueGraph] = useState("daily");

    const [dailyJobReport, setDailyJobReport] = useState("");
    const [yearlyJobReport, setYearlyJobReport] = useState("");
    const [monthlyJobReport, setMonthlyJobReport] = useState("");
    const [currentJobGraph, setCurrentJobGraph] = useState("daily");

    const token = localStorage.getItem(TOKEN_KEY);

    useEffect(() => {
        if (!token) {
            window.location.href = "/login";
        }

        axios.get(REPORT_SUMMARY)
            .then((response) => {
                setSummaryReport(response.data);
            })
            .catch((error) => {
                console.error(error);
                setAlert({ state: false, message: "Error getting summary report please reload" });
            })

        axios.get(REVENUE_REPORT + "daily")
            .then((response) => {
                const daily_report = [["Day", "Amount"]];

                for (let daily in response.data) {
                    let daily_revenue = [response.data[daily].payment_date, parseFloat(response.data[daily].daily_sale)];
                    daily_report.push(daily_revenue);
                }

                console.log(JSON.stringify(daily_report));
                setDailyRevenueReport(daily_report);
            })
            .catch((error) => {
                console.error(error);
                setAlert({ state: false, message: "Error getting revenue report please reload" });
            })

        axios.get(JOB_REPORT + "daily")
            .then((response) => {
                const daily_report = [["Day", "Count"]];

                for (let daily in response.data) {
                    let daily_jobs = [response.data[daily].date, parseFloat(response.data[daily].count)];
                    daily_report.push(daily_jobs);
                }

                console.log(JSON.stringify(daily_report));
                setDailyJobReport(daily_report);
            })
            .catch((error) => {
                console.error(error);
                setAlert({ state: false, message: "Error getting job report please reload" });
            })
    }, []);

    const divWidth = {
        width: "68%",
    };

    const divWidth2 = {
        width: "91%",
    };

    const divWidth3 = {
        width: "35%",
    };

    const divWidth4 = {
        width: "48%",
    };

    const divWidth5 = {
        width: "18%",
    };

    const divWidth6 = {
        width: "67%",
    };

    const options = {
        chart: {
            title: "Company Performance",
            subtitle: "Revenue",
        },
    };

    const revenueGraphChange = (graph) => {

        if (graph == "daily") {
            if (!dailyRevenueReport) {
                axios.get(REVENUE_REPORT + "daily")
                    .then((response) => {
                        const daily_report = [["Day", "Amount"]];

                        for (let daily in response.data) {
                            let daily_revenue = [response.data[daily].payment_date, parseFloat(response.data[daily].daily_sale)];
                            daily_report.push(daily_revenue);
                        }

                        console.log(JSON.stringify(daily_report));
                        setDailyRevenueReport(daily_report);
                    })
                    .catch((error) => {
                        console.error(error);
                        setAlert({ state: false, message: "Error getting revenue report please reload" });
                    })
            }
        } else if (graph == "monthly") {
            if (!monthlyRevenueReport) {
                axios.get(REVENUE_REPORT + "monthly")
                    .then((response) => {
                        const yearly_month = [
                            "January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"
                        ];
                        const monthly_report = [["Month", "Amount"]];

                        for (let monthly in response.data) {
                            let monthly_revenue = [yearly_month[response.data[monthly + 1].month] + " " + response.data[monthly].year, parseFloat(response.data[monthly].total_sales)];
                            monthly_report.push(monthly_revenue);
                        }

                        console.log(JSON.stringify(monthly_report));
                        setMonthlyRevenueReport(monthly_report);
                    })
                    .catch((error) => {
                        console.error(error);
                        setAlert({ state: false, message: "Error getting revenue report please reload" });
                    })
            }
        } else if (graph == "yearly") {
            if (!yearlyRevenueReport) {
                axios.get(REVENUE_REPORT + "yearly")
                    .then((response) => {
                        const yearly_report = [["Year of operation", "Amount"]];

                        for (let yearly in response.data) {
                            let yearly_revenue = [response.data[yearly].year.toString(), parseFloat(response.data[yearly].total_sales)];
                            yearly_report.push(yearly_revenue);
                        }

                        setYearlyRevenueReport(yearly_report);
                        console.log(JSON.stringify(yearly_report));
                    })
                    .catch((error) => {
                        console.error(error);
                        setAlert({ state: false, message: "Error getting revenue report please reload" });
                    })
            }
        }

        setCurrentRevenueGraph(graph);
    }

    const jobGraphChange = (graph) => {

        if (graph == "daily") {
            if (!dailyJobReport) {
                axios.get(JOB_REPORT + "daily")
                    .then((response) => {
                        const daily_report = [["Day", "Jobs posted"]];

                        for (let daily in response.data) {
                            let daily_jobs = [response.data[daily].date, parseFloat(response.data[daily].count)];
                            daily_report.push(daily_jobs);
                        }

                        console.log(JSON.stringify(daily_report));
                        setDailyJobReport(daily_report);
                    })
                    .catch((error) => {
                        console.error(error);
                        setAlert({ state: false, message: "Error getting job report please reload" });
                    })
            }
        } else if (graph == "monthly") {
            if (!monthlyJobReport) {
                axios.get(JOB_REPORT + "monthly")
                    .then((response) => {
                        const yearly_month = [
                            "", "January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"
                        ];
                        const monthly_report = [["Month", "Count"]];

                        for (let monthly in response.data) {
                            let monthly_jobs = [yearly_month[response.data[monthly].month] + " " + response.data[monthly].year, parseFloat(response.data[monthly].count)];
                            monthly_report.push(monthly_jobs);
                        }

                        console.log(JSON.stringify(monthly_report));
                        setMonthlyJobReport(monthly_report);
                    })
                    .catch((error) => {
                        console.error(error);
                        setAlert({ state: false, message: "Error getting job report please reload" });
                    })
            }
        } else if (graph == "yearly") {
            if (!yearlyJobReport) {
                axios.get(JOB_REPORT + "yearly")
                    .then((response) => {
                        const yearly_report = [["Year", "Count"]];

                        for (let yearly in response.data) {
                            let yearly_jobs = [response.data[yearly].year.toString(), parseFloat(response.data[yearly].count)];
                            yearly_report.push(yearly_jobs);
                        }

                        setYearlyJobReport(yearly_report);
                        console.log(JSON.stringify(yearly_report));
                    })
                    .catch((error) => {
                        console.error(error);
                        setAlert({ state: false, message: "Error getting job report please reload" });
                    })
            }
        }

        setCurrentJobGraph(graph);
    }

    return (
        <div>
            <Nav />
            <Sidebar activeTab="dashboard" />

            <section className="content home">
                <div className="container-fluid">

                    {alert ? <Alert alertState={alert && alert.state} message={alert && alert.message} /> : null}

                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <h2>Dashboard</h2>
                                <ul className="breadcrumb padding-0">
                                    <li className="breadcrumb-item"><a href="index.html"><i className="zmdi zmdi-home"></i></a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ul>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="input-group m-b-0">
                                    <input type="text" className="form-control" placeholder="Search..." />
                                    <span className="input-group-addon">
                                        <i className="zmdi zmdi-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-6">
                            <div className="card text-center">
                                <div className="body">
                                    <p className="m-b-20"><i className="zmdi zmdi-balance zmdi-hc-3x col-amber"></i></p>
                                    <span>Total Revenue</span>
                                    <h3 className="m-b-10">$<span>{summaryReport && summaryReport.payment}</span></h3>
                                    {/* <small className="text-muted">27% lower growth</small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="card text-center">
                                <div className="body">
                                    <p className="m-b-20"><i className="zmdi zmdi-assignment zmdi-hc-3x col-blue"></i></p>
                                    <span>Jobs</span>
                                    <h3>{summaryReport && summaryReport.jobs}</h3>
                                    {/* <small className="text-muted">88% lower growth</small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="card text-center">
                                <div className="body">
                                    <p className="m-b-20"><i className="zmdi zmdi-shopping-basket zmdi-hc-3x"></i></p>
                                    <span>Users</span>
                                    <h3>{summaryReport && summaryReport.users}</h3>
                                    {/* <small className="text-muted">38% lower growth</small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="card text-center">
                                <div className="body">
                                    <p className="m-b-20"><i className="zmdi zmdi-account-box zmdi-hc-3x col-green"></i></p>
                                    <span>Categories</span>
                                    <h3>{summaryReport && summaryReport.jobs}</h3>
                                    {/* <small className="text-muted">78% lower growth</small> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-6 col-sm-12">
                            <div class="card">
                                <div class="header">
                                    <h2><strong>Revenue</strong> report</h2>
                                    <ul class="header-dropdown">
                                        <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i class="zmdi zmdi-more"></i> </a>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li><a onClick={() => { revenueGraphChange("daily") }}>Daily Report</a></li>
                                                <li><a onClick={() => { revenueGraphChange("monthly") }}>Monthly Report</a></li>
                                                <li><a onClick={() => { revenueGraphChange("yearly") }}>Yearly Report</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div class="body">
                                    {currentRevenueGraph == "daily" ?
                                        (dailyRevenueReport ?
                                            <Chart
                                                chartType="Bar"
                                                width="100%"
                                                height="400px"
                                                data={dailyRevenueReport}
                                                options={options}
                                            />
                                            : "Loading"
                                        )
                                        : (currentRevenueGraph == "yearly" ?
                                            (yearlyRevenueReport ?
                                                <Chart
                                                    chartType="Bar"
                                                    width="100%"
                                                    height="400px"
                                                    data={yearlyRevenueReport}
                                                    options={options}
                                                />
                                                : "Loading"
                                            ) : (currentRevenueGraph == "monthly" ?
                                                (monthlyRevenueReport ?
                                                    <Chart
                                                        chartType="Bar"
                                                        width="100%"
                                                        height="400px"
                                                        data={monthlyRevenueReport}
                                                        options={options}
                                                    />
                                                    : "Loading"
                                                ) : null
                                            )
                                        )

                                    }
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="card">
                                <div class="header">
                                    <h2><strong>Job</strong> Posts</h2>
                                    <ul class="header-dropdown">
                                        <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i class="zmdi zmdi-more"></i> </a>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li><a onClick={() => { jobGraphChange("daily") }}>Daily</a></li>
                                                <li><a onClick={() => { jobGraphChange("monthly") }}>Monthly</a></li>
                                                <li><a onClick={() => { jobGraphChange("yearly") }}>Yearly</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div class="body">
                                    {currentJobGraph == "daily" ?
                                        (dailyJobReport ?
                                            <Chart
                                                chartType="Bar"
                                                width="100%"
                                                height="400px"
                                                data={dailyJobReport}
                                                options={options}
                                            />
                                            : "Loading"
                                        )
                                        : (currentJobGraph == "yearly" ?
                                            (yearlyJobReport ?
                                                <Chart
                                                    chartType="Bar"
                                                    width="100%"
                                                    height="400px"
                                                    data={yearlyJobReport}
                                                    options={options}
                                                />
                                                : "Loading"
                                            ) : (currentJobGraph == "monthly" ?
                                                (monthlyJobReport ?
                                                    <Chart
                                                        chartType="Bar"
                                                        width="100%"
                                                        height="400px"
                                                        data={monthlyJobReport}
                                                        options={options}
                                                    />
                                                    : "Loading"
                                                ) : null
                                            )
                                        )

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
















                    {/* <div className="row clearfix">
                        <div className="col-lg-4 col-md-6">
                            <div className="card">
                                <div className="header">
                                    <h2><strong>Campaign</strong> Performance</h2>
                                    <ul className="header-dropdown">
                                        <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i className="zmdi zmdi-more"></i> </a>
                                            <ul className="dropdown-menu slideUp">
                                                <li><a href="javascript:void(0);">Action</a></li>
                                                <li><a href="javascript:void(0);">Another action</a></li>
                                                <li><a href="javascript:void(0);">Something else</a></li>
                                                <li><a href="javascript:void(0);" className="boxs-close">Deletee</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="body m-b-10">
                                    <div className="progress-container l-black">
                                        <span className="progress-badge">Facebook Campaign</span>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="68" aria-valuemin="0"
                                                aria-valuemax="100" style={divWidth}>
                                                <span className="progress-value">68%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="body m-b-10">
                                    <div className="progress-container progress-info">
                                        <span className="progress-badge">Google AdSense</span>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="86" aria-valuemin="0"
                                                aria-valuemax="100" style={divWidth2}>
                                                <span className="progress-value">91%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="body m-b-20">
                                    <div className="progress-container progress-warning">
                                        <span className="progress-badge">LinkedIn Campaign</span>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="35" aria-valuemin="0"
                                                aria-valuemax="100" style={divWidth3}>
                                                <span className="progress-value">35%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="body align-center">
                                    <div className="row">
                                        <div className="col-4">
                                            <h4 className="margin-0">60%</h4>
                                            <p>Monthly</p>
                                        </div>
                                        <div className="col-4">
                                            <h4 className="margin-0">20%</h4>
                                            <p>Weekly</p>
                                        </div>
                                        <div className="col-4">
                                            <h4 className="margin-0">30%</h4>
                                            <p>Daily</p>
                                        </div>
                                    </div>
                                    <div className="sparkline-pie">6,1,3</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card">
                                <div className="header">
                                    <h2><strong>Browser</strong> Usage</h2>
                                    <ul className="header-dropdown">
                                        <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i className="zmdi zmdi-more"></i> </a>
                                            <ul className="dropdown-menu slideUp">
                                                <li><a href="javascript:void(0);">Action</a></li>
                                                <li><a href="javascript:void(0);">Another action</a></li>
                                                <li><a href="javascript:void(0);">Something else</a></li>
                                                <li><a role="button" className="boxs-close">Delete</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="body">
                                    <div className="doughnut">
                                        <canvas id="canvas" height="200"></canvas>
                                        <canvas id="tooltip-canvas" width="150" height="100"></canvas>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover m-b-0 m-t-20">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <small>Page Views</small>
                                                        <h5 className="m-b-0">32,211</h5>
                                                    </td>
                                                    <td>
                                                        <div className="sparkline m-t-10" data-type="bar" data-width="97%"
                                                            data-height="30px" data-bar-Width="3" data-bar-Spacing="7"
                                                            data-bar-Color="#2b314a">2,3,5,6,9,8,7,8,7</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <small>Site Visitors</small>
                                                        <h5 className="m-b-0">2,516</h5>
                                                    </td>
                                                    <td>
                                                        <div className="sparkline m-t-10" data-type="bar" data-width="97%"
                                                            data-height="30px" data-bar-Width="3" data-bar-Spacing="7"
                                                            data-bar-Color="#2b314a">8,5,3,2,2,3,5,6,4</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <small>Total Clicks</small>
                                                        <h5 className="m-b-0">4,536</h5>
                                                    </td>
                                                    <td>
                                                        <div className="sparkline m-t-10" data-type="bar" data-width="97%"
                                                            data-height="30px" data-bar-Width="3" data-bar-Spacing="7"
                                                            data-bar-Color="#2b314a">6,5,4,6,5,1,8,4,2</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <small>Total Returns</small>
                                                        <h5 className="m-b-0">516</h5>
                                                    </td>
                                                    <td>
                                                        <div className="sparkline m-t-10" data-type="bar" data-width="97%"
                                                            data-height="30px" data-bar-Width="3" data-bar-Spacing="7"
                                                            data-bar-Color="#2b314a">8,2,3,5,6,4,5,7,5</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h2><strong>Site</strong> Traffic</h2>
                                    <ul className="header-dropdown">
                                        <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i className="zmdi zmdi-more"></i> </a>
                                            <ul className="dropdown-menu slideUp">
                                                <li><a href="javascript:void(0);">Action</a></li>
                                                <li><a href="javascript:void(0);">Another action</a></li>
                                                <li><a href="javascript:void(0);">Something else</a></li>
                                                <li><a role="button" className="boxs-close">Delete</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="body m-b-10">
                                    <h5 className="m-b-0 number count-to" data-from="0" data-to="2651" data-speed="2000"
                                        data-fresh-interval="700">2651</h5>
                                    <p className="text-muted">Direct <span className="float-right">48%</span></p>
                                    <div className="progress m-b-20">
                                        <div className="progress-bar l-dark" role="progressbar" aria-valuenow="48" aria-valuemin="0"
                                            aria-valuemax="100" style={divWidth4}></div>
                                    </div>

                                    <h5 className="m-b-0 number count-to" data-from="0" data-to="251" data-speed="2000"
                                        data-fresh-interval="700">251</h5>
                                    <p className="text-muted">Referrals <span className="float-right">18%</span></p>
                                    <div className="progress m-b-20">
                                        <div className="progress-bar l-amber" role="progressbar" aria-valuenow="18"
                                            aria-valuemin="0" aria-valuemax="100" style={divWidth5}></div>
                                    </div>

                                    <h5 className="m-b-0 number count-to" data-from="0" data-to="941" data-speed="2000"
                                        data-fresh-interval="700">941</h5>
                                    <p className="text-muted">Search Engines <span className="float-right">67%</span></p>
                                    <div className="progress">
                                        <div className="progress-bar l-green" role="progressbar" aria-valuenow="67"
                                            aria-valuemin="0" aria-valuemax="100" style={divWidth6}></div>
                                    </div>
                                </div>
                                <div className="body">
                                    <p className="m-b-10">Page View <small className="float-right">3,665</small></p>
                                    <div id="sparkline14"></div>
                                    <p className="m-b-10 m-t-30">Bounce Rate <small className="float-right">2,925</small></p>
                                    <div id="sparkline15"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h2><strong>Application</strong> Sales <small>Members Preformance / Monthly Status</small>
                                    </h2>
                                    <ul className="header-dropdown">
                                        <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i className="zmdi zmdi-more"></i> </a>
                                            <ul className="dropdown-menu slideUp">
                                                <li><a href="javascript:void(0);">Action</a></li>
                                                <li><a href="javascript:void(0);">Another action</a></li>
                                                <li><a href="javascript:void(0);">Something else</a></li>
                                                <li><a href="javascript:void(0);" className="boxs-close">Deletee</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="body sales_report">
                                    <div className="table-responsive">
                                        <table className="table m-b-0 table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Application</th>
                                                    <th>Team</th>
                                                    <th>Change</th>
                                                    <th>Sales</th>
                                                    <th>Price</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h6>Alpino 4.1</h6>
                                                        <span>WrapTheme To By Again</span>
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled team-info">
                                                            <li><image src="assets/images/xs/avatar1.jpg" alt="Avatar" /></li>
                                                            <li><image src="assets/images/xs/avatar2.jpg" alt="Avatar" /></li>
                                                            <li><image src="assets/images/xs/avatar3.jpg" alt="Avatar" /></li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <div className="sparkline text-left" data-type="line" data-width="8em"
                                                            data-height="20px" data-line-Width="1.5" data-line-Color="#00c5dc"
                                                            data-fill-Color="transparent">3,5,1,6,5,4,8,3</div>
                                                    </td>
                                                    <td>11,200</td>
                                                    <td>$83</td>
                                                    <td><strong>$22,520</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Compass 2.0</h6>
                                                        <span>WrapTheme To By Again</span>
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled team-info">
                                                            <li><image src="assets/images/xs/avatar2.jpg" alt="Avatar" /></li>
                                                            <li><image src="assets/images/xs/avatar3.jpg" alt="Avatar" /></li>
                                                            <li><image src="assets/images/xs/avatar4.jpg" alt="Avatar" /></li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <div className="sparkline text-left" data-type="line" data-width="8em"
                                                            data-height="20px" data-line-Width="1.5" data-line-Color="#f4516c"
                                                            data-fill-Color="transparent">4,6,3,2,5,6,5,4</div>
                                                    </td>
                                                    <td>11,200</td>
                                                    <td>$66</td>
                                                    <td><strong>$13,205</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Nexa 1.1</h6>
                                                        <span>WrapTheme To By Again</span>
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled team-info">
                                                            <li><image src="assets/images/xs/avatar4.jpg" alt="Avatar" /></li>
                                                            <li><image src="assets/images/xs/avatar6.jpg" alt="Avatar" /></li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <div className="sparkline text-left" data-type="line" data-width="8em"
                                                            data-height="20px" data-line-Width="1.5" data-line-Color="#31db3d"
                                                            data-fill-Color="transparent">7,3,2,1,5,4,6,8</div>
                                                    </td>
                                                    <td>12,080</td>
                                                    <td>$93</td>
                                                    <td><strong>$17,700</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6>Oreo 2.2</h6>
                                                        <span>ThemeMakker To By Again</span>
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled team-info">
                                                            <li><image src="assets/images/xs/avatar1.jpg" alt="Avatar" /></li>
                                                            <li><image src="assets/images/xs/avatar3.jpg" alt="Avatar" /></li>
                                                            <li><image src="assets/images/xs/avatar2.jpg" alt="Avatar" /></li>
                                                            <li><image src="assets/images/xs/avatar9.jpg" alt="Avatar" /></li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <div className="sparkline text-left" data-type="line" data-width="8em"
                                                            data-height="20px" data-line-Width="1.5" data-line-Color="#2d342e"
                                                            data-fill-Color="transparent">3,1,2,5,4,6,2,3</div>
                                                    </td>
                                                    <td>18,200</td>
                                                    <td>$178</td>
                                                    <td><strong>$17,700</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-md-6 col-lg-4">
                            <div className="card">
                                <div className="header">
                                    <h2><strong>Follow</strong> Us <small>Description text here...</small></h2>
                                    <ul className="header-dropdown">
                                        <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i className="zmdi zmdi-more"></i> </a>
                                            <ul className="dropdown-menu slideUp">
                                                <li><a href="javascript:void(0);">Action</a></li>
                                                <li><a href="javascript:void(0);">Another action</a></li>
                                                <li><a href="javascript:void(0);">Something else</a></li>
                                                <li><a className="boxs-close">Delete</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="body">
                                    <ul className="follow_us list-unstyled">
                                        <li className="online">
                                            <a href="javascript:void(0);">
                                                <div className="media">
                                                    <image className="media-object " src="assets/images/xs/avatar4.jpg" alt="" />
                                                    <div className="media-body">
                                                        <span className="name">Chris Fox</span>
                                                        <span className="message">Designer, Blogger</span>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="online">
                                            <a href="javascript:void(0);">
                                                <div className="media">
                                                    <image className="media-object " src="assets/images/xs/avatar5.jpg" alt="" />
                                                    <div className="media-body">
                                                        <span className="name">Joge Lucky</span>
                                                        <span className="message">Java Developer</span>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="offline">
                                            <a href="javascript:void(0);">
                                                <div className="media">
                                                    <image className="media-object " src="assets/images/xs/avatar2.jpg" alt="" />
                                                    <div className="media-body">
                                                        <span className="name">Isabella</span>
                                                        <span className="message">CEO, Thememakker</span>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="offline">
                                            <a href="javascript:void(0);">
                                                <div className="media">
                                                    <image className="media-object " src="assets/images/xs/avatar1.jpg" alt="" />
                                                    <div className="media-body">
                                                        <span className="name">Folisise Chosielie</span>
                                                        <span className="message">Art director, Movie Cut</span>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="online">
                                            <a href="javascript:void(0);">
                                                <div className="media">
                                                    <image className="media-object " src="assets/images/xs/avatar3.jpg" alt="" />
                                                    <div className="media-body">
                                                        <span className="name">Alexander</span>
                                                        <span className="message">Writter, Mag Editor</span>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="card">
                                <div className="header">
                                    <h2><strong>Traffic</strong> this month <small>7% higher than last Month</small></h2>
                                    <ul className="header-dropdown">
                                        <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i className="zmdi zmdi-more"></i> </a>
                                            <ul className="dropdown-menu slideUp">
                                                <li><a href="javascript:void(0);">Action</a></li>
                                                <li><a href="javascript:void(0);">Another action</a></li>
                                                <li><a href="javascript:void(0);">Something else</a></li>
                                                <li><a className="boxs-close">Delete</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="body m-b-10">
                                    <div className="row">
                                        <div className="col-5 col-md-5 text-center">
                                            <input type="text" className="knob" value="86" data-width="50" data-height="50"
                                                data-thickness="0.2" data-fgColor="#e5678f" />
                                        </div>
                                        <div className="col-7 col-md-7">
                                            <h3 className="m-b-0">78.02 Gb</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="body l-coral overflowhidden">
                                    <p className="m-b-0">Outbound Bandwidth</p>
                                    <h3>340</h3>
                                    <div className="sparkline" data-type="line" data-spot-Radius="4"
                                        data-highlight-Spot-Color="#fff" data-highlight-Line-Color="#222"
                                        data-min-Spot-Color="#fff" data-max-Spot-Color="#fff" data-spot-Color="#fff"
                                        data-offset="90" data-width="100%" data-height="135px" data-line-Width="2"
                                        data-line-Color="#fff" data-fill-Color="transparent">2,3,5,6,4,5,1,2,3,2,5</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <div className="card">
                                <div className="header">
                                    <h2><strong>Invoice</strong></h2>
                                    <ul className="header-dropdown">
                                        <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i className="zmdi zmdi-more"></i> </a>
                                            <ul className="dropdown-menu slideUp">
                                                <li><a href="javascript:void(0);">Action</a></li>
                                                <li><a href="javascript:void(0);">Another action</a></li>
                                                <li><a href="javascript:void(0);">Something else</a></li>
                                                <li><a className="boxs-close">Delete</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <address>
                                                <strong>ThemeMakker Inc.</strong> <small
                                                    className="float-right">13/04/2018</small><br />
                                                795 Folsom Ave, Suite 546<br />
                                                San Francisco, CA 54656<br />
                                                <abbr title="Phone">P:</abbr> (123) 456-34636
                                            </address>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Item</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>COMPASS 2.0</td>
                                                    <td>$930</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>OREO 2.2</td>
                                                    <td>$525</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td><strong>$1455</strong></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button className="btn btn-warning btn-icon btn-icon-mini btn-round"><i
                                                className="zmdi zmdi-print"></i></button>
                                            <button className="btn btn-primary btn-round">Pay Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </div>
    );
}

export default Dashboard;