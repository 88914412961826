//Core components
import axios from "axios";
import React, { useState, useEffect } from "react";

//Global constants
import {
    TOKEN,
    GET_SUBURB,
    CREATE_USERS,
    GET_COUNTRIES,
} from "../Constants";


function UsersCreate(props) {

    const { closeModalBtn, setAlert, setAlertMessage } = props;

    const [getSuburbs, setSuburbs] = useState([]);
    const [getFormError, setFormError] = useState({});
    const [getCountries, setCountries] = useState("");
    const [getVerifyPassword, setVerifyPassword] = useState(false);
    const [getFormData, setFormData] = useState({
        name: '',
        email: '',
        dob: '',
        phonenumber: '',
        referred_by: '',
        id_number: '',
        picture: '',
        suburb: '',
        country: '',
        user_role: '',
        password: '',
        verify_password: '',
    });

    // const axiosInstance = axios.create({
    //     headers: {
    //         'Authorization': `Bearer ${TOKEN}`,
    //         'Content-Type': 'application/json',
    //     },
    // });

    useEffect(() => {
        axios.get(GET_SUBURB)
            .then((response) => {
                setSuburbs(response.data);
            })
            .catch((error) => {
                console.error(error);
            })


        axios.get(GET_COUNTRIES)
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }, [])

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        const { password } = getFormData;

        if (name == "verify_password") {
            password === value ? setVerifyPassword(false) : setVerifyPassword(true);
        }

        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { name, email, dob, phonenumber, referred_by, id_number, picture, suburb, country, user_role, password, verify_password } = getFormData;

        axios.post(CREATE_USERS, {
            name: name,
            email: email,
            dob: dob,
            phonenumber: phonenumber,
            referred_by: referred_by,
            id_number: id_number,
            picture: picture,
            suburb: suburb,
            country: country,
            user_role: user_role,
            password: password,
            verify_password: verify_password,
        })
            .then((response) => {
                setAlertMessage("User created succesfully");
                setAlert(true);
                closeModalBtn();
                console.log("Successfully sent " + JSON.stringify(response.data));
            })
            .catch((error) => {
                setAlertMessage("There was an error in creating record please try again!");
                //closeModalBtn();
                console.error(error);
                //alert(JSON.stringify(error.response));

                if (error.response.status == 422) {
                    setFormError(error.response.data.errors);
                }
            })
    }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create user</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                <div>
                                    <label>Full Name</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name="name"
                                        onChange={handleFormChange}
                                    />
                                    {getFormError.name ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.name}</span> : null}
                                </div>
                                <div>
                                    <label>Email</label>
                                    <input
                                        className='form-control'
                                        type='email'
                                        name="email"
                                        onChange={handleFormChange}
                                    />
                                    {getFormError.email ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.email}</span> : null}
                                </div>
                                <div>
                                    <label>Date of birth</label>
                                    <input
                                        className='form-control'
                                        type='date'
                                        name="dob"
                                        onChange={handleFormChange}
                                    />
                                    {getFormError.dob ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.dob}</span> : null}
                                </div>
                                <div>
                                    <label>Phone number</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name="phonenumber"
                                        onChange={handleFormChange}
                                    />
                                    {getFormError.phonenumber ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.phonenumber}</span> : null}
                                </div>
                                <div>
                                    <label>Referred by</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name="referred_by"
                                        onChange={handleFormChange}
                                    />
                                    {getFormError.refered_by ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.refered_by}</span> : null}
                                </div>
                                <div>
                                    <label>Id number</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name="id_number"
                                        onChange={handleFormChange}
                                    />
                                    {getFormError.id_number ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.id_number}</span> : null}
                                </div>
                                <div>
                                    <label>Picture</label>
                                    <input
                                        className='form-control'
                                        type='file'
                                        name="picture"
                                        onChange={handleFormChange}
                                    />
                                    {getFormError.picture ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.picture}</span> : null}
                                </div>
                                <div>
                                    <label>Suburb</label>
                                    <select className="form-control" name="suburb" onChange={handleFormChange}>
                                        <option>Select suburb</option>
                                        {getSuburbs ?
                                            getSuburbs.map((suburb, index) => (
                                                <option key={index} value={suburb.suburb}>{suburb.suburb}</option>
                                            ))
                                            : null
                                        }
                                    </select>
                                    {getFormError.suburb ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.suburb}</span> : null}
                                </div>
                                <div>
                                    <label>Country</label>
                                    <select className="form-control" name="country" onChange={handleFormChange}>
                                        <option>Select country</option>
                                        {getCountries ?
                                            getCountries.map((country, index) => (
                                                <option key={index} value={country.meta_key}>{country.meta_key}</option>
                                            ))
                                            : null
                                        }
                                    </select>
                                    {getFormError.country ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.country}</span> : null}
                                </div>
                                <div>
                                    <label>User role</label>
                                    <select className="form-control" name="user_role" onChange={handleFormChange}>
                                        <option>Select role</option>
                                        <option value="admin">Admin</option>
                                        <option value="agent">Agent</option>
                                        <option value="serviceProvider">Service Provider</option>
                                        <option value="customer">Customer</option>
                                    </select>
                                    {getFormError.user_role ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.user_role}</span> : null}
                                </div>
                                <div>
                                    <label>Password</label>
                                    <input
                                        className='form-control'
                                        type='password'
                                        name="password"
                                        onChange={handleFormChange}
                                    />
                                    {getFormError.password ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>{getFormError.password}</span> : null}
                                </div>
                                <div>
                                    <label>Verify Password</label>
                                    <input
                                        className='form-control'
                                        type='password'
                                        name="verify_password"
                                        onChange={handleFormChange}
                                    />
                                </div>
                                {getVerifyPassword ? <span style={{ color: "red", fontStyle: "italic", fontSize: 11 }}>Passwords do not match</span> : null}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersCreate;