//Core components
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

//Project components
import Alert from "../components/Alert";
import Delete from "../components/forms/Delete";
import CountryCreate from "../components/forms/CountryCreate";
import CategoryCreate from "../components/forms/CategoryCreate";

//External css and js
import 'datatables.net-dt';
import "../components/css/multi-select.css";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

//Global constants
import {
    TOKEN_KEY,
    GET_COUNTRIES,
    DELETE_COUNTRY,
    DELETE_CATEGORY,
    GET_JOBS_CATEGORIES,
    JOB_CATEGORY_UPDATE,
} from "../components/Constants";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";

function Operatons() {

    const [getAlert, setAlert] = useState('');
    const [getCountries, setCountries] = useState('');
    const [getDeleteCountry, setDeleteCountry] = useState('');
    const [getDeleteDialog, setDeleteDialog] = useState(false);
    const [getDeleteCategory, setDeleteCategory] = useState('');
    const [getJobsCategories, setJobsCategories] = useState('');
    const [getJobCategoryToEdit, setJobCategoryToEdit] = useState('');
    const [getCurrentDeleteParam, setCurrentDeleteParam] = useState('');
    const [getShowCreateCountry, setShowCreateCountry] = useState(false);
    const [getShowCreateCategory, setShowCreateCategory] = useState(false);
    const [getJobCategoryEditedName, setJobCategoryEditedName] = useState('');

    //Check if user is logged in
    const token = localStorage.getItem(TOKEN_KEY);
    const navigate = useNavigate();

    if (!token) {
        window.location.href = "/login";
    }

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    //Handle edit category button
    const editCategory = (id) => {
        setJobCategoryToEdit(id);
    };

    //Handle edit category form change
    const categoryFormChange = (e) => {
        const { name, value } = e.target;
        setJobCategoryEditedName(value);
    }

    //Show create category dialog
    const createCategory = () => {
        setShowCreateCategory(true);
    }

    const deleteCategory = (id) => {
        setDeleteCategory(id);
        setDeleteDialog(true);
        setCurrentDeleteParam('category');
    };

    const deleteAlert = (alert) => {
        setAlert({ status: alert.status, message: alert.message });
        navigate('/operations');
    }

    const closeCategory = () => {
        setShowCreateCategory(false);
    }

    /**
     * 
     * Country operations
     */

    const createCounty = () => {
        setShowCreateCountry(true);
    }

    const closeCountry = () => {
        setShowCreateCountry(false);
    }

    const deleteCountry = (id) => {
        setDeleteCountry(id);
        setDeleteDialog(true);
        setCurrentDeleteParam('country');
    }

    const closeDeleteDialog = () => {
        setDeleteDialog(false);
    }

    const createCountryAlert = (alert) => {
        setAlert({ status: alert.status, message: alert.message });
        navigate('/operations');
    }

    const createCategoryAlert = (alert) => {
        setAlert({ status: alert.status, message: alert.message });
        navigate('/operations');
    }

    //handle submit category form change
    const handleSubmitCategoryChange = () => {
        axiosInstance.post(JOB_CATEGORY_UPDATE, {
            id: getJobCategoryToEdit,
            name: getJobCategoryEditedName
        })
            .then((response) => {
                setAlert({ status: true, message: "Category successfully edited" });
                //setJobCategoryEditedName('');
                window.location.href = "/operations";
            })
            .catch((error) => {
                console.error(error);
            })
    }

    //Style the category and the action buttons
    const categoryRow = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    };

    const inputStyle = {
        width: "70%"
    }

    const buttonColor = {
        color: "white"
    }

    useEffect(() => {

        axiosInstance.get(GET_JOBS_CATEGORIES)
            .then((response) => {
                setJobsCategories(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

        axiosInstance.get(GET_COUNTRIES)
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

    }, []);

    return (
        <div>
            <Nav />
            <Sidebar activeTab="operations" />

            <section className="content">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <h2>Operations</h2>
                                <ul className="breadcrumb ">
                                    <li className="breadcrumb-item"><a href="index.html"><i className="zmdi zmdi-home"></i></a></li>
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Operations</li>
                                </ul>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="input-group m-b-0">
                                    <input type="text" className="form-control" placeholder="Search..." />
                                    <span className="input-group-addon">
                                        <i className="zmdi zmdi-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {getAlert ?
                            <Alert alertState={getAlert.status} message={getAlert.message} />
                            : null
                        }

                        {getShowCreateCategory ?
                            <CategoryCreate closeModalBtn={closeCategory} setCategoryAlert={createCategoryAlert} />
                            : null
                        }

                        {getShowCreateCountry ?
                            <CountryCreate closeModalBtn={closeCountry} setCountryAlert={createCountryAlert} />
                            : null
                        }

                        {getDeleteDialog ?
                            getCurrentDeleteParam == "category" ?
                                <Delete
                                    object_id={getDeleteCategory}
                                    setObjectDeleteAlert={deleteAlert}
                                    closeModalBtn={closeDeleteDialog}
                                    url={DELETE_CATEGORY}
                                    parent="Category"
                                />
                                : getCurrentDeleteParam == "country" ?
                                    <Delete
                                        object_id={getDeleteCountry}
                                        setObjectDeleteAlert={deleteAlert}
                                        closeModalBtn={closeDeleteDialog}
                                        url={DELETE_COUNTRY}
                                        parent="Country"
                                    />
                                    : null
                            : null
                        }
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-12">
                            <div className="card">
                                <div className="header row">
                                    <h2 className="col-lg-8"><strong>Job</strong> Categories <small>Below is a list of all the service categories to be used by the system.</small> </h2>
                                    <a className="col-lg-3 btn btn-primary btn-round" onClick={createCategory} style={buttonColor}>Create</a>
                                </div>
                                <div className="body">
                                    <ul className="list-group">
                                        {getJobsCategories ?
                                            getJobsCategories.map((category, index) => (
                                                <li key={index} className="list-group-item" style={categoryRow}>
                                                    {getJobCategoryToEdit === category.id ?
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="category_change"
                                                            value={getJobCategoryEditedName ? getJobCategoryEditedName : category.name}
                                                            style={inputStyle}
                                                            onChange={(e) => categoryFormChange(e)}
                                                        />
                                                        : <Link style={{ cursor: "pointer" }} to={"/jobs/" + category.id}>{category.name}</Link>
                                                    }
                                                    <span>
                                                        {getJobCategoryToEdit === category.id ?
                                                            <button
                                                                className="btn btn-icon btn-neutral btn-icon-mini"
                                                                onClick={handleSubmitCategoryChange}>
                                                                <i className="zmdi zmdi-thumb-up"></i>
                                                            </button>
                                                            : null

                                                        }
                                                        <button className="btn btn-icon btn-neutral btn-icon-mini" onClick={() => editCategory(category.id)}><i className="zmdi zmdi-edit"></i></button>
                                                        <button className="btn btn-icon btn-neutral btn-icon-mini" onClick={() => deleteCategory(category.id)}><i className="zmdi zmdi-delete"></i></button>
                                                    </span>
                                                </li>
                                            ))
                                            : "Loading..."
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="card">
                                <div className="header row">
                                    <h2 className="col-lg-8">Countries</h2>
                                    <a className="col-lg-3 btn btn-primary btn-round" onClick={createCounty} style={buttonColor}>Create</a>
                                </div>
                                <div className="body">
                                    <ul className="list-group">
                                        {getCountries ?
                                            getCountries.map((country, index) => (
                                                <li key={index} className="list-group-item" style={categoryRow}>
                                                    <span>
                                                        {country.meta_key}
                                                    </span>
                                                    <span>
                                                        {country.meta_value}
                                                    </span>
                                                    <button
                                                        className="btn btn-icon btn-neutral btn-icon-mini"
                                                        onClick={() => deleteCountry(country.id)}>
                                                        <i className="zmdi zmdi-delete"></i>
                                                    </button>
                                                </li>
                                            ))
                                            : "Loading..."
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Operatons;