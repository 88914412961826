//Main components
import axios from "axios";
import React, { useState, useEffect } from "react";

//Project components
import Alert from "../components/Alert";
import PaymentCreate from "../components/forms/PaymentCreate";
import { PAYMENTS, REPORT_SUMMARY } from "../components/Constants";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";

function Payments() {
    const [alert, setAlert] = useState('');
    const [paymentList, setPaymentList] = useState('');
    const [summaryReport, setSummaryReport] = useState('');
    const [manualPayment, setManualPayment] = useState(false);

    useEffect(() => {
        axios.get(REPORT_SUMMARY)
            .then((data) => {
                setSummaryReport(data.data);
            })
            .then((error) => {
                console.error(error);
            })

        axios.get(PAYMENTS)
            .then((data) => {
                setPaymentList(data.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }, []);

    const makePayment = () => {
        setManualPayment(true);
    }

    const closeMakePayment = () => {
        setManualPayment(false);
    }

    const alertDialog = (alertObj) => {
        setAlert({ state: alertObj.status, message: alertObj.message });
    }

    return (
        <div>
            <Nav />
            <Sidebar activeTab="payments" />

            <section className="content home">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <h2>Payments</h2>
                                <ul className="breadcrumb padding-0">
                                    <li className="breadcrumb-item"><a href="index.html"><i className="zmdi zmdi-home"></i></a></li>
                                    <li className="breadcrumb-item active">Payments</li>
                                </ul>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="input-group m-b-0">
                                    <input type="text" className="form-control" placeholder="Search..." />
                                    <span className="input-group-addon">
                                        <i className="zmdi zmdi-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {manualPayment ? <PaymentCreate closeModalBtn={closeMakePayment} setPaymentAlert={alertDialog} /> : null}
                    {alert ? <Alert alertState={alert.state} message={alert.message} /> : null}
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-6">
                            <div className="card text-center">
                                <div className="body">
                                    <p className="m-b-20"><i className="zmdi zmdi-balance zmdi-hc-3x col-amber"></i></p>
                                    <span>Total Revenue</span>
                                    <h3 className="m-b-10">$<span>{summaryReport && summaryReport.payment}</span></h3>
                                    {/* <small className="text-muted">27% lower growth</small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="card text-center">
                                <div className="body">
                                    <p className="m-b-20"><i className="zmdi zmdi-assignment zmdi-hc-3x col-blue"></i></p>
                                    <span>Total Credits Purchased</span>
                                    <h3>{summaryReport && summaryReport.credits}</h3>
                                    {/* <small className="text-muted">88% lower growth</small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="card text-center">
                                <div className="body">
                                    <p className="m-b-20"><i className="zmdi zmdi-shopping-basket zmdi-hc-3x"></i></p>
                                    <span>Current Credits</span>
                                    <h3 className="m-b-10">190</h3>
                                    {/* <small className="text-muted">38% lower growth</small> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="card text-center">
                                <div className="body">
                                    <p className="m-b-20"><i className="zmdi zmdi-account-box zmdi-hc-3x col-green"></i></p>
                                    <span>Registered Users</span>
                                    <h3 className="m-b-10">{summaryReport && summaryReport.users}</h3>
                                    {/* <small className="text-muted">78% lower growth</small> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h2><strong>Payments</strong> History <small>All the payments made to the system</small>
                                    </h2>
                                    <button type="button" className="btn btn-primary" onClick={makePayment}>Make Manual Payment</button>
                                    {/* <ul className="header-dropdown">
                                        <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <i className="zmdi zmdi-more"></i> </a>
                                            <ul className="dropdown-menu slideUp">
                                                <li><a href="javascript:void(0);">Action</a></li>
                                                <li><a href="javascript:void(0);">Another action</a></li>
                                                <li><a href="javascript:void(0);">Something else</a></li>
                                                <li><a href="javascript:void(0);" className="boxs-close">Deletee</a></li>
                                            </ul>
                                        </li>
                                    </ul> */}
                                </div>
                                <div className="body sales_report">
                                    <div className="table-responsive">
                                        <table className="table m-b-0 table-hover">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Customer name</th>
                                                    <th>Amount paid</th>
                                                    <th>Credits Purchased</th>
                                                    <th>Rate Used</th>
                                                    <th>Payment Gateway</th>
                                                    <th>Payment Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paymentList ? paymentList.map((payment, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <h6>{payment && payment.user && payment.user.name}</h6>
                                                            <span>{payment && payment.user && payment.user.email}</span>
                                                            <span>{payment && payment.user && payment.user.phonenumber}</span>
                                                        </td>
                                                        <td>
                                                            {payment && payment.amount}
                                                        </td>
                                                        <td>
                                                            {payment && payment.credit_purchased}
                                                        </td>
                                                        <td>{payment && payment.rate_used}</td>
                                                        <td>{payment && payment.payment_gateway}</td>
                                                        <td><strong>{payment && payment.created_at}</strong></td>
                                                    </tr>
                                                )) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Payments;