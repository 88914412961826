//Main components
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

//Project components
import Nav from "../components/Nav";
import Alert from "../components/Alert";
import Spinner from "../components/Spinner";
import Sidebar from "../components/Sidebar";
import {
    PHONE,
    GET_USER,
    USER_ROLE,
    TOKEN_KEY,
    FILE_DOMAIN,
    RESET_PASSWORD,
    DIRECT_CREDIT_INJECT,
    CONFIRM_PASSWORD_ERROR,
} from "../components/Constants";

function Profile() {

    const [getAlert, setAlert] = useState(null);
    const [getCredits, setCredits] = useState(null);
    const [userData, setUserData] = useState(null);
    const [getPasswordConfirm, setPasswordConfirm] = useState(true);
    const [getFormData, setFormData] = useState({
        password: '',
        verify_password: '',
    });

    const navigate = useNavigate();
    const phone = localStorage.getItem(PHONE);
    const token = localStorage.getItem(TOKEN_KEY);
    const user_role = localStorage.getItem(USER_ROLE);

    const { id } = useParams();

    useEffect(() => {
        if (!token)
            window.location.href = "/login";

        axios.get(GET_USER + id)
            .then((response) => {
                setUserData(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

    }, []);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        const { password } = getFormData;

        if (name == "verify_password") {
            password === value ? setPasswordConfirm(true) : setPasswordConfirm(false);
        }

        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { password } = getFormData;

        axios.post(RESET_PASSWORD, {
            phone: phone,
            password: password
        })
            .then((response) => {
                setAlert({ status: true, message: "Password rest successful" });
            })
            .catch((error) => {
                setAlert({ status: false, message: "Failed to reset please try again" });
            })
    }

    const changeCredits = (e) => {
        const { value } = e.target;

        setCredits(value);
    }

    const sendAddCredits = (e) => {
        e.preventDefault();

        axios.post(DIRECT_CREDIT_INJECT, {
            user: id,
            entered_by: phone,
            credit: getCredits,
        })
            .then((response) => {
                setAlert({ status: true, message: "Credits have been successfully entered into the system." });
            })
            .catch((error) => {
                setAlert({ status: false, message: "Failure to set the credit, please try again. If the problem persist please contact admin." });
            })
    }

    return (
        <div>
            <Nav />
            <Sidebar activeTab="profile" />

            <section className="content profile-page">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <h2>Profile</h2>
                                <ul className="breadcrumb padding-0">
                                    <li className="breadcrumb-item"><a href="index.html"><i className="zmdi zmdi-home"></i></a></li>
                                    <li className="breadcrumb-item"><a onClick={() => navigate("/dashboard")}>Dashboard</a></li>
                                    <li className="breadcrumb-item active">Profile</li>
                                </ul>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="input-group m-b-0">
                                    <input type="text" className="form-control" placeholder="Search..." />
                                    <span className="input-group-addon">
                                        <i className="zmdi zmdi-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="body bg-dark profile-header">
                                    <div className="row">
                                        <div className="col-lg-10 col-md-12">
                                            <div className="detail" style={{ marginLeft: 0, display: "flex", flexDirection: "row" }}>
                                                <i className="zmdi zmdi-account" style={{ fontSize: 80 }}></i>
                                                <div className="u_name">
                                                    {userData ? <h4><strong>{userData && userData.name}</strong></h4> : <Spinner />}
                                                    {userData ? <div><span>{userData && userData.email}</span><br /></div> : <Spinner />}
                                                    {userData ? <span>{userData && userData.phonenumber}</span> : <Spinner />}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-2 col-md-12 user_earnings">
                                            <h6>Total Earnings</h6>
                                            <h4>$<small className="number count-to" data-from="0" data-to="2124" data-speed="1500" data-fresh-interval="1000">2124</small></h4>
                                            <input type="text" className="knob" value="39" data-width="80" data-height="80" data-thickness="0.1" data-bgcolor="#485058" data-fgColor="#f97c53" />
                                            <span>Average 39% <i className="zmdi zmdi-caret-up text-success"></i></span>
                                        </div> */}
                                    </div>
                                </div>
                                <ul className="nav nav-tabs profile_tab">
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#overview">Overview</a></li>
                                    {/* <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#schedule">Schedule</a></li> */}
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#changePassword">Change Password</a></li>
                                </ul>
                            </div>


                            {getAlert ? <Alert alertState={getAlert.status} message={getAlert.message} /> : null}
                            {getPasswordConfirm ? null : <Alert alertState={getPasswordConfirm} message={CONFIRM_PASSWORD_ERROR} />}

                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="overview">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-6">
                                            <div className="card text-center">
                                                <div className="body">
                                                    <i className="zmdi zmdi-money-box zmdi-hc-2x"></i>
                                                    {userData ? <h5 className="m-b-0 number count-to">{userData && userData.credit_balance}</h5> : <Spinner />}
                                                    <small>Credit Balance</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-6">
                                            <div className="card text-center">
                                                <div className="body">
                                                    <i className="zmdi zmdi-account-add zmdi-hc-2x"></i>
                                                    {userData ? <h5 className="m-b-0 number count-to">{userData && (userData.roles.length > 0) ? userData.roles[0].name : "Not assigned"}</h5> : <Spinner />}
                                                    <small>User role</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-6">
                                            <div className="card text-center">
                                                <div className="body">
                                                    <i className="zmdi zmdi-wrench zmdi-hc-2x"></i>
                                                    {userData ? <h5 className="m-b-0 number count-to">{userData.services_offered.length}</h5> : <Spinner />}
                                                    <small>Services Offered</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-6">
                                            <div className="card text-center">
                                                <div className="body">
                                                    <i className="zmdi zmdi-pin zmdi-hc-2x"></i>
                                                    {userData ? <h5 className="m-b-0 number count-to">{userData.country}</h5> : <Spinner />}
                                                    <small>Country</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12">
                                            <div className="card">
                                                <div className="header">
                                                    <h2><strong>Info</strong></h2>
                                                </div>
                                                {userData ? <div className="body">
                                                    <small className="text-muted">Address: </small>
                                                    <p>{userData.suburb}, {userData.country}</p>
                                                    <hr />
                                                    <small className="text-muted">Referral Code: </small>
                                                    <p>{userData.referral_code}</p>
                                                    <hr />
                                                    <small className="text-muted">Id Number: </small>
                                                    <p className="m-b-0">{userData.id_number}</p>
                                                    <hr />
                                                    <small className="text-muted">Approved by: </small>
                                                    <p className="m-b-0">{userData.approved_by}</p>
                                                    <hr />
                                                    <small className="text-muted">User joined at: </small>
                                                    <p className="m-b-0">{userData.created_at}</p>
                                                </div> : <Spinner />}
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-12">
                                            <div class="card">
                                                {user_role == "admin" ?
                                                    <div>
                                                        <div class="header">
                                                            <h2><strong>Add</strong> Credits</h2>
                                                        </div>
                                                        <div class="body m-b-10">
                                                            <div class="form-group">
                                                                <input type="number" name="credits" className="form-control" onChange={changeCredits} />
                                                            </div>
                                                            <div class="post-toolbar-b">
                                                                <button class="btn btn-warning btn-icon btn-icon-mini btn-round"><i class="zmdi zmdi-long-arrow-right" onClick={sendAddCredits}></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                }

                                            </div>

                                            <div className="row">
                                                {userData && userData.files ? userData.files.map((file, index) => (
                                                    <div key={index} className="file_manager col-4">
                                                        <div className="file">
                                                            <a href={FILE_DOMAIN + file.meta_value}>
                                                                <div className="icon">
                                                                    <i className="zmdi zmdi-collection-pdf"></i>
                                                                </div>
                                                                <div className="file-name">
                                                                    <p className="m-b-5 text-muted">{file.meta_key}</p>
                                                                    {/* <small>Size: 3MB <span className="date text-muted">Aug 18, 2017</span></small> */}
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )) : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div role="tabpanel" className="tab-pane" id="changePassword">
                                    <div className="body card" style={{ paddingLeft: 20, paddingRight: 20 }}>
                                        <div className="form-group" style={{ paddingTop: 20 }}>
                                            <input type="password" name="password" onChange={handleFormChange} className="form-control" placeholder="New Password" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="verify_password" onChange={handleFormChange} className="form-control" placeholder="Confirm Password" />
                                        </div>
                                        <button className="btn btn-info btn-round" type="button" onClick={handleFormSubmit}>Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Profile;