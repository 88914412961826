//Core components
import axios from "axios";
import { useParams, Link, Navigate, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

//Project components
import Nav from "../components/Nav";
import Alert from "../components/Alert";
import Spinner from "../components/Spinner";
import Sidebar from "../components/Sidebar";

import {
    GET_JOB,
    EDIT_JOB,
    TOKEN_KEY,
    UPDATE_JOB,
    GET_JOBS_CATEGORIES,
} from "../components/Constants";

function Job() {

    const [alert, setAlert] = useState({});
    const [editPage, setEditPage] = useState(false);
    const [categories, setCategories] = useState(null);
    const [serviceJob, setServiceJob] = useState(null);
    const [getCreateJobValidation, setCreateJobValidation] = useState('');

    const [getFormData, setFormData] = useState({
        active: '',
        title: '',
        category: '',
        description: '',
        country: '',
        cost: '',
        status: '',
        minimum: '',
        maximum: '',
    });

    const navigate = useNavigate();
    const { id } = useParams();
    const { edit } = useParams();

    //Set the token
    const token = localStorage.getItem(TOKEN_KEY);

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    useEffect(() => {
        //Mask the input fields if edit job is false
        EDIT_JOB == edit ? setEditPage(true) : setEditPage(false);

        if (!token) {
            window.location.href = "/login";
        }

        axiosInstance.get(GET_JOB + "/" + id + "/" + 0)
            .then((response) => {
                setServiceJob(response.data);
            })
            .catch((error) => {
                setAlert({ status: false, message: "Failed to get job information please try again and if the problem persist contact admin" });
            })

        //Get categories
        axiosInstance.get(GET_JOBS_CATEGORIES)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                setAlert({ status: false, message: "Failed to get categories please try again and if the problem persist contact admin" });
            })
    }, []);

    const formError = {
        color: "red",
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const jobFormHandleSubmit = (e) => {
        e.preventDefault();
        //const { title, category, description, location, start_date, requirements, salary } = getCreateJob;
        const { title, active, category, description, country, cost, status, minimum, maximum } = getFormData;

        axiosInstance.post(UPDATE_JOB, {
            id: id,
            title: title,
            category: category,
            description: description,
            location: country,
            min_budget: minimum,
            max_budget: maximum,
            active: active,
            viewing_cost_credit: cost,
            job_status: status
        })
            .then((response) => {
                setAlert({ status: true, message: "Job successfully created" });
                navigate.navigate("/jobs/0");
            })
            .catch((error) => {
                console.error(error);
                setAlert({ status: false, message: "Job could not be created. Please contact admin" });
                if (error.response.status == 422)
                    setCreateJobValidation(error.response.data.errors);
                else if (error.response.status == 404)
                    setAlert(error.response.data.errors);
            });
    }

    return (
        <div>

            <Nav />
            <Sidebar activeTab="jobs" />
            <section class="content">
                <div class="container-fluid">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            {alert.status ? <Alert alertState={alert.status} message={alert.message} /> : null}
                            {serviceJob ?
                                <div class="card">
                                    <div class="header">
                                        <h2><strong>{serviceJob.title}</strong></h2>
                                        <ul class="header-dropdown">
                                            <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i class="zmdi zmdi-more"></i> </a>
                                                <ul class="dropdown-menu dropdown-menu-right">
                                                    <li><Link to={"/job/" + id + "/" + EDIT_JOB}>Edit</Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="body">
                                        <form onSubmit={jobFormHandleSubmit}>
                                            <div class="row clearfix">
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Title</b>
                                                    <input type="text" name="title" onChange={handleFormChange} className="form-control" value={serviceJob && serviceJob.title} />
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Who posted the job?</b>
                                                    <input type="text" className="form-control" value={serviceJob && serviceJob.user && serviceJob.user.name} disabled={true} />
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Who posted the job: email?</b>
                                                    <input type="text" className="form-control" value={serviceJob && serviceJob.user && serviceJob.user.email} disabled={true} />
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Who posted the job: phone number?</b>
                                                    <input type="text" className="form-control" value={serviceJob && serviceJob.user && serviceJob.user.phonenumber} disabled={true} />
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Is job active</b>
                                                    <select className="form-control" name="active" onChange={handleFormChange}>
                                                        <option selected={serviceJob.active == 1 ? true : false}>Yes</option>
                                                        <option selected={serviceJob.active == 0 ? true : false}>No</option>
                                                    </select>
                                                    {getCreateJobValidation.active ?
                                                        <div className="help-info" style={formError}>{getCreateJobValidation.active[0]}</div>
                                                        : null
                                                    }
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Reviewed?</b>
                                                    <select class="form-control" name="" disabled={true}>
                                                        <option selected={serviceJob.active_reviewed == 1 ? true : false}>Yes</option>
                                                        <option selected={serviceJob.active_reviewed == 0 ? true : false}>No</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Reviewed by</b>
                                                    <input type="text" className="form-control" name="" value={serviceJob.reviewed_by && serviceJob.reviewed_by.name} disabled={true} />
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Categories</b>
                                                    <select class="form-control show-tick" name="category" onChange={handleFormChange} multiple>
                                                        {categories ? categories.map((category, index) => (
                                                            <option key={index}>{category.name}</option>
                                                        )) : <Spinner />}
                                                    </select>
                                                    {getCreateJobValidation.category ?
                                                        <div className="help-info" style={formError}>{getCreateJobValidation.category[0]}</div>
                                                        : null
                                                    }
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Description</b>
                                                    <textarea className="form-control" name="description" onChange={handleFormChange} disabled={!editPage}>
                                                        {serviceJob.description}
                                                    </textarea>
                                                    {getCreateJobValidation.description ?
                                                        <div className="help-info" style={formError}>{getCreateJobValidation.description[0]}</div>
                                                        : null
                                                    }
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Country, town</b>
                                                    <input type="text" className="form-control" name="country" onChange={handleFormChange} value={serviceJob.suburb ? serviceJob.suburb.country + ", " + serviceJob.suburb.suburb : ""} disabled={!editPage} />
                                                    {getCreateJobValidation.location ?
                                                        <div className="help-info" style={formError}>{getCreateJobValidation.location[0]}</div>
                                                        : null
                                                    }
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Viewing cost</b>
                                                    <input type="number" className="form-control" name="cost" onChange={handleFormChange} value={serviceJob.viewing_cost_credit} disabled={!editPage} />
                                                    {getCreateJobValidation.viewing_cost_credit ?
                                                        <div className="help-info" style={formError}>{getCreateJobValidation.viewing_cost_credit[0]}</div>
                                                        : null
                                                    }
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Status</b>
                                                    <input type="text" className="form-control" name="status" onChange={handleFormChange} value={serviceJob.status} disabled={!editPage} />
                                                    {getCreateJobValidation.job_status ?
                                                        <div className="help-info" style={formError}>{getCreateJobValidation.job_status[0]}</div>
                                                        : null
                                                    }
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Minimum Budget</b>
                                                    <input type="number" step="0.01" className="form-control" name="minimum" onChange={handleFormChange} value={serviceJob.viewing_cost_credit} disabled={!editPage} />
                                                    {getCreateJobValidation.min_budget ?
                                                        <div className="help-info" style={formError}>{getCreateJobValidation.min_budget[0]}</div>
                                                        : null
                                                    }
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Maximum Budget</b>
                                                    <input type="number" step="0.01" className="form-control" name="maximum" onChange={handleFormChange} value={serviceJob.min_budget} disabled={!editPage} />
                                                    {getCreateJobValidation.max_budget ?
                                                        <div className="help-info" style={formError}>{getCreateJobValidation.max_budget[0]}</div>
                                                        : null
                                                    }
                                                </div>
                                                {/* <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Minimum Budget</b>
                                                    <input type="text" className="form-control" name="" onChange={handleFormChange} value={serviceJob.max_budget} disabled={!editPage} />
                                                </div> */}
                                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20">
                                                    <b>Created at</b>
                                                    <input type="text" className="form-control" name="" onChange={handleFormChange} value={serviceJob.created_at} disabled={true} />
                                                </div>
                                            </div>
                                            <button className="btn btn-primary col-8" disabled={!editPage}>Edit</button>
                                        </form>
                                    </div>
                                </div>
                                : <Spinner />}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default Job;