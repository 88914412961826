//Core components
import axios from "axios";
import React, { useEffect, useState } from "react";

//Global constants
import {
    TOKEN_KEY,
    CREATE_CATEGORY
} from "../Constants";


function CategoryCreate(props) {

    const { closeModalBtn, setCategoryAlert } = props;
    const [getFormData, setFormData] = useState({
        category_name: '',
    });

    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
        window.location.href = "/login";
    }

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { category_name } = getFormData;

        axiosInstance.post(CREATE_CATEGORY, {
            category_name: category_name,
        })
            .then((response) => {
                setCategoryAlert({ status: true, message: "Category successfully created" });
                closeModalBtn();
                console.log("Successfully sent " + JSON.stringify(response.data));
            })
            .catch((error) => {
                setCategoryAlert({ status: false, message: "There was an error in creating record please try again!" });
                //setCategoryAlertMessage("There was an error in creating record please try again!");
                closeModalBtn();
                console.error("Error creating leave: ", error);
            })
    }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create category</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                <div>
                                    <label>Category name</label>
                                    <input type="text" name="category_name" className="form-control" onChange={handleFormChange} required />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CategoryCreate;