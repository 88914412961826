//Core components
import axios from "axios";
import React, { useEffect, useState } from "react";

//Global constants
import {
    TOKEN_KEY,
} from "../Constants";


function Delete(props) {

    const { closeModalBtn, setObjectDeleteAlert, object_id, parent, url } = props;

    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
        window.location.href = "/login";
    }

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });


    const handleFormSubmit = (e) => {
        e.preventDefault();
        //const { object_id } = object_id;

        axiosInstance.post(url, {
            id: object_id,
        })
            .then((response) => {
                setObjectDeleteAlert({ status: true, message: parent + " successfully deleted " + parent });
                closeModalBtn('');
                console.log("Successfully sent " + JSON.stringify(response.data));
            })
            .catch((error) => {
                setObjectDeleteAlert({ status: false, message: "There was an error in deleting record please try again!" });
                closeModalBtn('');
                console.error("Error creating leave: ", error);
            })
    }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm deletion</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                <div>
                                    <p>Are you sure you want to delete the {parent}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Delete
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Delete;