//Core components
import $ from "jquery";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//Project components
import Alert from "../components/Alert";

//External css and js
import 'datatables.net-dt';
import "../components/css/multi-select.css";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

//Global constants
import Nav from "../components/Nav";
import Delete from "../components/forms/Delete";
import Sidebar from "../components/Sidebar";
import {
    TOKEN,
    PHONE,
    VIEW_JOB,
    EDIT_JOB,
    GET_JOBS,
    TOKEN_KEY,
    JOB_DELETE,
    GET_SUBURB,
    APPROVE_JOB,
    CREATE_JOBS,
    GET_PENDING_JOBS,
    GET_JOBS_CATEGORIES,
} from "../components/Constants";

function Jobs() {

    const [getJobs, setJobs] = useState('');
    const [getCredits, setCredits] = useState('');
    const [deleteJob, setDeleteJob] = useState(false);
    const [currentJob, setCurrentJob] = useState(null);
    const [getPendingJobs, setPendingJobs] = useState('');
    const [getJobsCategries, setJobsCategries] = useState('');
    const [getJobsLocations, setJobsLocations] = useState('');
    const [getCreateJobAlert, setCreateJobAlert] = useState('');
    const [getCreditsError, setCreditsError] = useState({ status: false });
    const [getCreateJobValidation, setCreateJobValidation] = useState('');
    const [getCreateJob, setCreateJob] = useState({
        title: '',
        category: '',
        description: '',
        location: '',
        start_date: '',
        //end_date: '',
        salary: '',
        requirements: '',
    });

    const navigate = useNavigate();

    //Check if user is logged in
    const token = localStorage.getItem(TOKEN_KEY);
    const phone = localStorage.getItem(PHONE);

    if (!token) {
        window.location.href = "/login";
    }

    const formError = {
        color: "red",
    };

    //Get the category id
    const { id } = useParams();

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    const jobFormHandleChange = (e) => {
        const { name, value } = e.target;
        setCreateJob({
            ...getCreateJob,
            [name]: value
        });
    };

    const openJob = (id) => {
        navigate('/pendingjobs/?id=' + id);
    };

    const jobFormHandleSubmit = (e) => {
        e.preventDefault();
        const { title, category, description, location, start_date, requirements, salary } = getCreateJob;

        axiosInstance.post(CREATE_JOBS, {
            title: title,
            category: category,
            description: description,
            location: location,
            start_date: start_date,
            //end_date: end_date,
            phone: phone,
            salary: salary,
            requirements: requirements
        })
            .then((response) => {
                setCreateJobAlert({ status: true, message: "Job successfully created" });
            })
            .catch((error) => {
                console.error(error);
                setCreateJobAlert({ status: false, message: "Job could not be created. Please contact admin" });
                if (error.response.status == 422)
                    setCreateJobValidation(error.response.data.errors);
                else if (error.response.status == 404)
                    setCreateJobAlert(error.response.data.errors);
            });
    }

    const approveJob = (job_id) => {
        if (phone) {
            if (getCredits) {
                setCreditsError({ status: false, id: job_id });
                axiosInstance.post(APPROVE_JOB, {
                    job_id: job_id,
                    user: phone,
                    credits: getCredits
                })
                    .then((response) => {
                        setCreateJobAlert({ status: true, message: "Job successfully approved" });
                        //navigate("/jobs");
                    })
                    .catch((error) => {
                        console.error(error);
                        setCreateJobAlert({ status: false, message: "Failed to approved job, please contact admin" });
                    })
            } else
                setCreditsError({ status: true, id: job_id });
        } else
            navigate("/login");

    }

    const creditsChange = (e) => {
        const { name, value } = e.target;

        setCredits(value);
    }

    useEffect(() => {
        //const jobs_url = GET_JOBS;

        //Get jobs
        axiosInstance.get(GET_JOBS + id)
            .then((response) => {
                setJobs(response.data);
                //console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.error('Error:', error);
            });

        //Get job categories
        axiosInstance.get(GET_JOBS_CATEGORIES)
            .then((response) => {
                setJobsCategries(response.data);
            })
            .catch((error) => {
                console.error('Error:', error);
            })

        //Get suburbs
        axiosInstance.get(GET_SUBURB)
            .then((response) => {
                setJobsLocations(response.data);
            })
            .catch((error) => {
                console.error('Error:', error);
            })

        //Get pending jobs
        axiosInstance.get(GET_PENDING_JOBS)
            .then((response) => {
                setPendingJobs(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

    }, []);

    const deleteJobFn = (id) => {
        setDeleteJob(true);
        setCurrentJob(id);
    }

    const deleteAlert = (alert) => {
        setCreateJobAlert({ status: alert.status, message: alert.message });
        navigate('/jobs/0');
    }

    const closeDeleteDialog = () => {
        setDeleteJob(false);
    }


    return (
        <div>
            <Nav />
            <Sidebar activeTab="jobs" />
            <section className="content contact">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <h2>Job</h2>
                                <ul className="breadcrumb ">
                                    <li className="breadcrumb-item"><a href="index.html"><i className="zmdi zmdi-home"></i></a></li>
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Job</li>
                                </ul>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="input-group m-b-0">
                                    <input type="text" className="form-control" placeholder="Search..." />
                                    <span className="input-group-addon">
                                        <i className="zmdi zmdi-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            {getCreateJobAlert ? <Alert alertState={getCreateJobAlert.status} message={getCreateJobAlert.message} /> : null}
                            <div className="card">
                                <div className="body m-b-10">
                                    <ul className="nav nav-tabs padding-0">
                                        <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#Grid">Grid</a></li>
                                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#List">List</a></li>
                                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#Addnew">Add New</a></li>
                                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#PendingJob">Pending Job</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane active" id="Grid">
                                    <div className="row">
                                        {getJobs ?
                                            getJobs.map((job, index) => (
                                                <div key={index} className="col-lg-3">
                                                    <Link to={"/job/" + job.id + "/" + EDIT_JOB}>
                                                        <div className="card">
                                                            <div className="body text-center">
                                                                {/* <span><i className="zmdi zmdi-comment rounded-circle"></i></span> */}
                                                                <h6>{job && job.user && job.user.name}</h6>
                                                                <p><i className="zmdi zmdi-phone m-r-10"></i> {job && job.user && job.user.email}</p>
                                                                <p><i className="zmdi zmdi-flare"></i> {job.active_reviewed == "1" ? "Approved" : "Not Approved"}</p>
                                                                <p><i className="zmdi zmdi-flare"></i> {job && job.category && job.category.name}</p>
                                                                <p><i className="zmdi zmdi-comment m-r-10"></i> {job && job.title}</p>
                                                                <small><i className="zmdi zmdi-calendar-alt m-r-10"></i> {job && job.created_at}</small>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                            : "Loading..."
                                        }
                                    </div>
                                </div>
                                <div className="tab-pane" id="List">
                                    <div className="card">
                                        <div className="body">
                                            <div className="table-responsive">
                                                <table className="table table-hover m-b-0 c_list">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th data-breakpoints="xs">Email / Phone</th>
                                                            <th data-breakpoints="xs sm md">Active</th>
                                                            <th data-breakpoints="xs sm md">Category</th>
                                                            <th data-breakpoints="xs sm md">Title</th>
                                                            <th data-breakpoints="xs sm md">Start</th>
                                                            <th data-breakpoints="xs">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {getJobs ?
                                                            getJobs.map((job, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="checkbox">
                                                                            <input id="delete_2" type="checkbox" />
                                                                            <label for="delete_2">&nbsp;</label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <p className="c_name">{job && job.user && job.user.name} <span className="badge badge-default m-l-10 hidden-sm-down">Family</span></p>
                                                                    </td>
                                                                    <td>
                                                                        <span className="phone"><i className="zmdi zmdi-phone m-r-10"></i><i className="zmdi zmdi-email m-r-10"></i>{job && job.user && job.user.email}</span>
                                                                    </td>
                                                                    <td>
                                                                        <address><i className="zmdi zmdi-flare"></i>{job.active_reviewed ? "Approved" : "Not Approved"}</address>
                                                                    </td>
                                                                    <td>
                                                                        <address><i className="zmdi zmdi-flare"></i>{job && job.category && job.category.name}</address>
                                                                    </td>
                                                                    <td>
                                                                        <span className="phone"><i className="zmdi zmdi-comment m-r-10"></i>{job && job.title}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="phone"><i className="zmdi zmdi-calendar-alt m-r-10"></i>{job && job.start_date}</span>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={"/job/" + job.id + "/" + EDIT_JOB} className="btn btn-icon btn-neutral btn-icon-mini"><i className="zmdi zmdi-edit"></i></Link>
                                                                        <button className="btn btn-icon btn-neutral btn-icon-mini" onClick={() => deleteJobFn(job.id)}><i className="zmdi zmdi-delete"></i></button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : "Loading"
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="Addnew">
                                    <div className="card">
                                        <div className="header">
                                            <h2><strong>Add</strong> & New Job</h2>
                                            {/* <ul className="header-dropdown">
                                                <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i className="zmdi zmdi-more"></i> </a>
                                                    <ul className="dropdown-menu slideUp">
                                                        <li><a href="javascript:void(0);">Action</a></li>
                                                        <li><a href="javascript:void(0);">Another action</a></li>
                                                        <li><a href="javascript:void(0);">Something else</a></li>
                                                        <li><a href="javascript:void(0);" className="boxs-close">Delete</a></li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                                        </div>
                                        <div className="body">
                                            <form onSubmit={jobFormHandleSubmit}>
                                                <div className="row clearfix">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <input type="text" name="title" className="form-control" onChange={jobFormHandleChange} placeholder="Job title" />
                                                            {getCreateJobValidation.title ?
                                                                <div className="help-info" style={formError}>{getCreateJobValidation.title[0]}</div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <select className="form-control" name="category" onChange={jobFormHandleChange}>
                                                            <option>Select category</option>
                                                            {getJobsCategries ?
                                                                getJobsCategries.map((category, index) => (
                                                                    <option key={index} value={category.id}>{category.name}</option>
                                                                ))
                                                                : null
                                                            }
                                                        </select>
                                                        {getCreateJobValidation.category ?
                                                            <div className="help-info" style={formError}>{getCreateJobValidation.category[0]}</div>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="col-12">
                                                        <textarea className="form-control" rows="3" name="description" placeholder="Enter description" onChange={jobFormHandleChange}></textarea>
                                                        {getCreateJobValidation.description ?
                                                            <div className="help-info" style={formError}>{getCreateJobValidation.description[0]}</div>
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="col-12">
                                                        <textarea className="form-control" rows="3" name="requirements" placeholder="Enter job requirements" onChange={jobFormHandleChange}></textarea>
                                                        {getCreateJobValidation.requirements ?
                                                            <div className="help-info" style={formError}>{getCreateJobValidation.requirements[0]}</div>
                                                            : null
                                                        }
                                                    </div>
                                                    {/* <div className="row"> */}
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            {/* <input type="text" name="location" className="form-control" placeholder="Enter Address" onChange={jobFormHandleChange} /> */}
                                                            <select className="form-control" name="location" onChange={jobFormHandleChange}>
                                                                <option>Select suburb</option>
                                                                {getJobsLocations ?
                                                                    getJobsLocations.map((location, index) => (
                                                                        <option key={index} value={location.id}>{location.suburb}</option>
                                                                    ))
                                                                    : null
                                                                }
                                                            </select>
                                                            {getCreateJobValidation.location ?
                                                                <div className="help-info" style={formError}>{getCreateJobValidation.location[0]}</div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <input type="number" step="0.01" name="salary" className="form-control" placeholder="Enter budgeted money" onChange={jobFormHandleChange} />
                                                            {getCreateJobValidation.salary ?
                                                                <div className="help-info" style={formError}>{getCreateJobValidation.salary[0]}</div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                    <div className="col-6">
                                                        <label>Start date</label>
                                                        <input type="date" className="form-control" name="start_date" onChange={jobFormHandleChange} />
                                                        {getCreateJobValidation.start_date ?
                                                            <div className="help-info" style={formError}>{getCreateJobValidation.start_date[0]}</div>
                                                            : null
                                                        }
                                                    </div>
                                                    {/* <div className="col-6">
                                                        <label>End date</label>
                                                        <input type="date" className="form-control" name="end_date" onChange={jobFormHandleChange} />
                                                        {getCreateJobValidation.end_date ?
                                                            <div className="help-info" style={formError}>{getCreateJobValidation.end_date[0]}</div>
                                                            : null
                                                        }
                                                    </div> */}
                                                    <button type="submit" className="btn btn-primary btn-round btn-block margin" style={{ marginTop: 30 }}>Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                        {/* <div className="body m-t-10">
                                            <div className="row clearfix">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Facebook" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <input type="password" className="form-control" placeholder="Twitter" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <input type="number" className="form-control" placeholder="Linkedin" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <input type="number" className="form-control" placeholder="instagram" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-primary btn-round">Save All</button>
                                                    <button className="btn btn-primary btn-simple btn-round">Cancel</button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="tab-pane" id="PendingJob">
                                    <div className="row clearfix">
                                        {getPendingJobs ?
                                            getPendingJobs.map((job, index) => (
                                                <div key={index} className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                    <div className="card">
                                                        <div className="header">
                                                            <h2><strong>{job && job.user && job.user.name}</strong> <small>{job && job.category && job.category.name}</small></h2>
                                                            <ul className="header-dropdown">
                                                                <li className="dropdown"> <a href="javascript:void(0);" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i className="zmdi zmdi-more"></i> </a>
                                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                                        <li><a href="javascript:void(0);">Action</a></li>
                                                                        <li><a href="javascript:void(0);">Another action</a></li>
                                                                        <li><a href="javascript:void(0);">Something else</a></li>
                                                                        <li><a href="javascript:void(0);" className="boxs-close">Delete</a></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <a>
                                                            <div className="body">
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <h6 className="m-b-15" onClick={() => openJob(job.id)}>{job && job.title}</h6>
                                                                    <div>
                                                                        <input type="number" name="credits" className="form-control" placeholder="Credits" onChange={creditsChange} />
                                                                        {getCreditsError.status ? (getCreditsError.id == job.id ? <span style={{ fontSize: 11, fontStyle: "italic", color: "red" }}>Enter credits for the job</span> : null) : null}
                                                                        <button className="btn btn-primary" onClick={() => approveJob(job.id)}>Approve Job</button>
                                                                    </div>
                                                                </div>
                                                                <p>{job && job.description}</p>
                                                                <div className="progress-container l-black m-b-20">
                                                                    <span className="progress-badge">{job && job.start_date}</span>
                                                                    {/* <div className="progress">
                                                            <div className="progress-bar" role="progressbar" aria-valuenow="68" aria-valuemin="0" aria-valuemax="100">
                                                                <span className="progress-value">68%</span>
                                                            </div>
                                                        </div> */}
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <small>{job && job.tags}</small>
                                                                        <h6>{job && job.viewing_credit_cost}</h6>
                                                                    </div>
                                                                    {/* <div className="col-5">
                                                                    <div className="sparkline text-right m-t-10" data-type="bar" data-width="97%" data-height="26px" data-bar-Width="2" data-bar-Spacing="7" data-bar-Color="#7460ee">2,5,8,3,5,7,1,6</div>
                                                                </div> */}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            ))
                                            : "Loading..."
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {deleteJob ?
                    <Delete
                        object_id={currentJob}
                        setObjectDeleteAlert={deleteAlert}
                        closeModalBtn={closeDeleteDialog}
                        url={JOB_DELETE}
                        parent={"Job"}
                    />
                    : null}
            </section>
        </div>
    );
}

export default Jobs;