//Main react components
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//Project components
import Alert from "../../components/Alert";
import {
    NAME,
    USER,
    PHONE,
    LOGIN,
    COMPANY,
    TOKEN_KEY,
    USER_ROLE,
} from "../../components/Constants";

function Login() {

    const [getError, setError] = useState('');
    const [getFormError, setFormError] = useState('');
    const [getAuthFormError, setAuthFormError] = useState('');

    const [getFormData, setFormData] = useState({
        phoneEmail: '',
        password: ''
    });

    const navigate = useNavigate();
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
        window.location.href = "/dashboard";
    }

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { phoneEmail, password } = getFormData;
        console.log(phoneEmail + " " + password);

        axios.post(LOGIN, {
            phoneEmail: phoneEmail,
            password: password
        })
            .then((response) => {
                console.log("Response " + JSON.stringify(response.data));
                if (response.data.user.active) {
                    if (response.data.user_role == "admin" || response.data.user_role == "agent") {
                        localStorage.setItem(TOKEN_KEY, response.data.token);
                        localStorage.setItem(PHONE, response.data.user.phonenumber);
                        localStorage.setItem(NAME, response.data.user.name);
                        localStorage.setItem(USER, response.data.user.id);
                        localStorage.setItem(USER_ROLE, response.data.user_role);
                        window.location.href = "/dashboard";
                    } else {
                        setError("User is not authorised to login to this application. Contact your admin");
                    }
                } else
                    setError("The user is not active please contact admin!");
            })
            .catch((error) => {
                //console.log("There was an error " + JSON.stringify(error.response.status));
                console.log(error);
                if (error.response && error.response.status == 422)
                    setAuthFormError(error.response.data.errors);
                else if (error.response && error.response.status == 404)
                    setAuthFormError(error.response.data.errors);
            });
    }

    const formError = {
        color: "red",
        fontSize: 11,
        fontStyle: "italic"
    };

    return (
        <div className="authentication">
            <div className="container">
                <div className="col-md-12 content-center">
                    {getError ?
                        <div class="alert alert-danger">
                            <strong>Error!</strong> {getError}
                        </div>
                        : null
                    }
                    {getFormError ? <Alert alertState={false} message={getFormError} /> : null}
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="company_detail">
                                <div>
                                    {/* <img src={logo} alt="Logo" style={logoWidth} /> */}
                                    <h6 className="logo">{COMPANY} </h6>
                                </div>
                                <div className="footer">
                                    {/* <ul className="social_link list-unstyled">
                                        <li><a href="https://thememakker.com" title="ThemeMakker"><i className="zmdi zmdi-globe"></i></a></li>
                                        <li><a href="https://themeforest.net/user/thememakker" title="Themeforest"><i className="zmdi zmdi-shield-check"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/thememakker/" title="LinkedIn"><i className="zmdi zmdi-linkedin"></i></a></li>
                                        <li><a href="https://www.facebook.com/thememakkerteam" title="Facebook"><i className="zmdi zmdi-facebook"></i></a></li>
                                        <li><a href="http://twitter.com/thememakker" title="Twitter"><i className="zmdi zmdi-twitter"></i></a></li>
                                        <li><a href="http://plus.google.com/+thememakker" title="Google plus"><i className="zmdi zmdi-google-plus"></i></a></li>
                                        <li><a href="https://www.behance.net/thememakker" title="Behance"><i className="zmdi zmdi-behance"></i></a></li>
                                    </ul> */}
                                    <hr />
                                    <ul>
                                        <li><a href="http://kotikoti.co.zw/" target="_blank">Website</a></li>
                                        <li><a href="https://kotikoti.co.zw/contact" target="_blank">Contact Us</a></li>
                                        <li><a href="https://kotikoti.co.zw/about" target="_blank">About Us</a></li>
                                        <li><a href="https://kotikoti.co.zw/terms" target="_blank">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 offset-lg-1">
                            <div className="card-plain">
                                <div className="header">
                                    <h5>Log in</h5>
                                </div>
                                <form className="form" onSubmit={handleSubmit}>
                                    <div className="input-group">
                                        <input type="text" className="form-control" name="phoneEmail" onChange={handleFormChange} placeholder="Email or Phone Number" required />
                                        <span className="input-group-addon"><i className="zmdi zmdi-account-circle"></i></span>
                                    </div>
                                    {getAuthFormError.phoneEmail ?
                                        <div className="help-info" style={formError}>{getAuthFormError.phoneEmail}</div>
                                        : null
                                    }
                                    <div className="input-group">
                                        <input type="password" onChange={handleFormChange} name="password" placeholder="Password" className="form-control" required />
                                        <span className="input-group-addon"><i className="zmdi zmdi-lock"></i></span>
                                    </div>
                                    {getAuthFormError.password ?
                                        <div className="help-info" style={formError}>{getAuthFormError.password}</div>
                                        : null
                                    }
                                    <div className="footer">
                                        <button type="submit" className="btn btn-primary btn-round btn-block">SIGN IN</button>
                                        {/* <Link to="/register" className="btn btn-primary btn-simple btn-round btn-block">SIGN UP</Link> */}
                                    </div>
                                </form>
                                <a href="" className="link">Forgot Password?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;