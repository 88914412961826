//Core components
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

//Project components
import Job from './pages/Job';
import Jobs from './pages/Jobs';
import Users from './pages/Users';
import Home from './components/Home';
import Profile from './pages/Profile';
import Login from './pages/auth/Login';
import Payments from './pages/Payments';
import Logout from './pages/auth/Logout';
import Dashboard from './pages/Dashboard';
import Operations from './pages/Operations';
import Register from './pages/auth/Register';
import PendingJobs from './pages/PendingJobs';

//External style and js components
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const pages = (
  <Route>
    <Route path="/" element={<Dashboard />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/jobs/:id" element={<Jobs />} />
    <Route path="/job/:id/:edit" element={<Job />} />
    <Route path="/pendingjobs" element={<PendingJobs />} />
    <Route path="/profile/:id" element={<Profile />} />
    <Route path="/operations" element={<Operations />} />
    <Route path="/users" element={<Users />} />
    <Route path="/payments" element={<Payments />} />
  </Route>
);

const auth = (
  <Route>
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route path="/logout" element={<Logout />} />
  </Route>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {pages}
      {auth}
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
