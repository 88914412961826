//Core components
import axios from "axios";
import React, { useEffect, useState } from "react";

//Global constants
import {
    PHONE,
    TOKEN_KEY,
    ACTIVATE_USER,
} from "../Constants";


function ActivateUser(props) {

    const { closeModalBtn, setUserAlert, actionPoint, user } = props;
    console.log("User " + JSON.stringify(user));

    const token = localStorage.getItem(TOKEN_KEY);
    const phone = localStorage.getItem(PHONE);

    if (!token) {
        window.location.href = "/login";
    }

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    const handleFormSubmit = (e) => {
        e.preventDefault();

        axiosInstance.post(ACTIVATE_USER, {
            phone: phone,
            user: user.id
        })
            .then((response) => {
                setUserAlert({ status: true, message: "User successfully " + actionPoint });
                closeModalBtn();
                //console.log("Successfully sent " + JSON.stringify(response.data));
            })
            .catch((error) => {
                setUserAlert({ status: false, message: "There was an error " + actionPoint + "ing user, please try again!" });
                //setUserAlertMessage("There was an error in creating record please try again!");
                closeModalBtn();
                console.error("Error creating leave: ", error);
            })
    }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm action</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                <h5>Are you sure you want to {actionPoint} {user.name}</h5>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActivateUser;