//Core components
import axios from "axios";
import React, { useEffect, useState } from "react";

//Global constants
import {
    PHONE,
    TOKEN_KEY,
    GET_USERS,
    CREATE_MANUAL_PAYMENT
} from "../Constants";


function PaymentCreate(props) {

    const { closeModalBtn, setPaymentAlert } = props;

    const [users, setUsers] = useState([]);
    const [getFormData, setFormData] = useState({
        name: '',
        amount: ''
    });

    const token = localStorage.getItem(TOKEN_KEY);
    const phone = localStorage.getItem(PHONE);

    useEffect(() => {
        if (!token) {
            window.location.href = "/login";
        }

        axios.get(GET_USERS)
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }, []);

    const axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { name, amount } = getFormData;

        axiosInstance.post(CREATE_MANUAL_PAYMENT, {
            name: name,
            phone: phone,
            amount: amount
        })
            .then((response) => {
                setPaymentAlert({ status: true, message: "Payment successfully created" });
                closeModalBtn();
                //console.log("Successfully sent " + JSON.stringify(response.data));
            })
            .catch((error) => {
                setPaymentAlert({ status: false, message: "There was an error in creating record please try again!" });
                //setPaymentAlertMessage("There was an error in creating record please try again!");
                closeModalBtn();
                console.error("Error creating leave: ", error);
            })
    }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create payment</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                <div>
                                    <label>Select User</label>
                                    <select className="form-control" name="name" onChange={handleFormChange} required>
                                        <option>Select user</option>
                                        {users ? users.map((user, index) => (
                                            <option key={index} value={user && user.id}>{user && user.name} {user && user.phonenumber}</option>
                                        )) : null}
                                    </select>
                                </div>
                                <div>
                                    <label>Amount</label>
                                    <input className="form-control" onChange={handleFormChange} name="amount" type="number" step="0.01" required />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentCreate;