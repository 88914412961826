//Domain
const domain = "https://backend.kotikoti.co.zw";
//const domain = "http://localhost:8000";
export const FILE_DOMAIN = "https://backend.kotikoti.co.zw/public/storage/";

//Site variables
export const USER = "patik-user";
export const USER_ROLE = "patik-user-auth";
export const TOKEN_KEY = "patik-auth-key";
export const EMAIL = "patik-email";
export const PHONE = "patik-phone";
export const NAME = "patik-name";
export const TOKEN = "2|Raf16JasAqGOsRBPZPX2XB7Nk3MO1wV7sZ2DHjHS20b70445";
export const COMPANY = "Koti Koti";
export const EDIT_JOB = 1;
export const VIEW_JOB = 0;
export const CONFIRM_PASSWORD_ERROR = "Passwords do not match";

//Users endpoints
export const GET_USER = domain + "/api/user/";
export const LOGIN = domain + "/api/users/login";
export const GET_USERS = domain + "/api/users/get";
export const GET_USER_ROLE = domain + "/api/users/role/";
export const CREATE_USERS = domain + "/api/users/create";
export const ACTIVATE_USER = domain + "/api/users/activate";
export const RESET_PASSWORD = domain + "/api/users/resetpassword";

//Jobs endpoints
export const GET_JOB = domain + "/api/job";
export const GET_JOBS = domain + "/api/jobscategory/";
export const JOB_DELETE = domain + "/api/jobs/delete";
export const UPDATE_JOB = domain + "/api/jobs/update";
export const APPROVE_JOB = domain + "/api/job/approve";
export const CREATE_JOBS = domain + "/api/jobs/create";
export const GET_PENDING_JOBS = domain + "/api/jobs/pending";
export const GET_JOBS_CATEGORIES = domain + "/api/jobs/categories";
export const CREATE_CATEGORY = domain + "/api/jobs/category/create";
export const DELETE_CATEGORY = domain + "/api/jobs/category/delete";
export const JOB_CATEGORY_UPDATE = domain + "/api/jobs/category/update";

//Countries endpoints
export const GET_SUBURB = domain + "/api/suburbs";
export const GET_COUNTRIES = domain + "/api/countries";
export const CREATE_COUNTRY = domain + "/api/country/create";
export const DELETE_COUNTRY = domain + "/api/country/delete";

//Package endpoints
export const GET_PACKAGE = domain + "/api/paymentpackage";
export const GET_PACKAGE_NAMES = domain + "/api/package/names";
export const CREATE_PACKAGE = domain + "/api/paymentpackage/create";

//Recruits endpoints
export const GET_RECRUITS = domain + "/api/recruits";
export const CREATE_RECRUITS = domain + "/api/recruits/create";

//Loan endpoints
export const GET_LOAN = domain + "/api/loan";
export const SET_LOAN = domain + "/api/loan/create";

//Options endpoints
export const GET_CURRENCY = domain + "/api/object/get/currency";

//Operations endpoints
export const GET_OPERATIONS = domain + "/api/operations";
export const GET_OPERATIONS_PARENT = domain + "/api/operations/parent";

//Reports
export const PAYMENTS = domain + "/api/payments";
export const JOB_REPORT = domain + "/api/report/job/";
export const REPORT_SUMMARY = domain + "/api/report/summary";
export const REVENUE_REPORT = domain + "/api/report/revenue/";
export const DIRECT_CREDIT_INJECT = domain + "/api/payment/credit/inject";

//Payments
export const CREATE_MANUAL_PAYMENT = domain + "/api/payment/createManualPayment";