//React main components
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

//Project components
import Alert from "../../components/Alert";
import {
    NAME,
    EMAIL,
    TOKEN_KEY,
    CREATE_USERS,
    CONFIRM_PASSWORD_ERROR,
} from "../../components/Constants";

function Register() {

    const [getFormError, setFormError] = useState('');
    const [getPasswordConfirm, setPasswordConfirm] = useState(true);
    const [getUserCreatedStatus, setUserCreatedStatus] = useState(true);
    const [getUserCreatedError, setUserCreatedError] = useState(true);

    const [getFormData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        referral_code: '',
        id_number: '',
        picture: '',
        latitude: '',
        longitude: '',
        password: '',
        confirm_password: ''
    });

    const token = localStorage.getItem(TOKEN_KEY);
    const formError = {
        color: "red",
    };

    if (token) {
        window.location.href = "/dashboard";
    }

    // const axiosInstance = axios.create({
    //     headers: {
    //         'Authorization': `Bearer ${TOKEN}`,
    //         'Content-Type': 'application/json',
    //     },
    // });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        const { password } = getFormData;

        if (name == "confirm_password") {
            password === value ? setPasswordConfirm(true) : setPasswordConfirm(false);
        }

        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { name, email, phone, referral_code, id_number, picture, latitude, longitude, password } = getFormData;

        axios.post(CREATE_USERS, {
            name: name,
            email: email,
            phone: phone,
            referral_code: referral_code,
            id_number: id_number,
            picture: picture,
            latitude: latitude,
            longitude: longitude,
            password: password
        }).then((response) => {
            setUserCreatedStatus(true);
            console.log(response.data);
            localStorage.setItem(TOKEN_KEY, response.data.token); //Get the token and set it on the localstorage
            localStorage.setItem(EMAIL, response.data.user.email);
            localStorage.setItem(NAME, response.data.user.name);

            window.location.href = '/login';
        }).catch((error) => {
            setUserCreatedStatus(false);
            setUserCreatedError(error.message);
            setFormError(error.response.data.errors); //Get specific form error
            console.error(error);
            console.log("Error " + JSON.stringify(error.response.data.message));
        })

        console.log(name + " " + email + " " + phone + " " + password);
    }

    return (
        <div className="authentication">
            <div className="container">
                <div className="col-md-12 content-center">
                    {getPasswordConfirm ? null : <Alert alertState={getPasswordConfirm} message={CONFIRM_PASSWORD_ERROR} />}
                    {getUserCreatedStatus ? null : <Alert alertState={getUserCreatedStatus} message={getUserCreatedError} />}
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="company_detail">
                                <h4 className="logo">Patik Services</h4>
                                <h3>The home <strong>of all the</strong>services you need done.</h3>
                                <p>Patik is a register service provider, which is a service directory for buyers and sellers of services</p>
                                <div className="footer">
                                    <ul className="social_link list-unstyled">
                                        <li><a href="#" title="ThemeMakker"><i className="zmdi zmdi-globe"></i></a></li>
                                        <li><a href="#" title="Themeforest"><i className="zmdi zmdi-shield-check"></i></a></li>
                                        <li><a href="#" title="LinkedIn"><i className="zmdi zmdi-linkedin"></i></a></li>
                                        <li><a href="#" title="Facebook"><i className="zmdi zmdi-facebook"></i></a></li>
                                        <li><a href="#" title="Twitter"><i className="zmdi zmdi-twitter"></i></a></li>
                                        <li><a href="#" title="Google plus"><i className="zmdi zmdi-google-plus"></i></a></li>
                                        <li><a href="#" title="Behance"><i className="zmdi zmdi-behance"></i></a></li>
                                    </ul>
                                    <hr />
                                    <ul>
                                        <li><a href="#" target="_blank">Contact Us</a></li>
                                        <li><a href="#" target="_blank">About Us</a></li>
                                        <li><a href="#" target="_blank">Services</a></li>
                                        <li><a href="#">FAQ</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 offset-lg-1">
                            <div className="card-plain">
                                <div className="header">
                                    <h5>Sign Up</h5>
                                    <span>Register a new user</span>
                                </div>
                                <form className="form" onSubmit={handleFormSubmit}>
                                    <div className="input-group">
                                        <input type="text" name="name" onChange={handleFormChange} className="form-control" placeholder="Enter Full Name (*)" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-account-circle"></i></span>
                                        {getFormError.name ?
                                            <div className="help-info" style={formError}>{getFormError.name[0]}</div>
                                            : null
                                        }
                                    </div>
                                    <div className="input-group">
                                        <input type="email" name="email" onChange={handleFormChange} className="form-control" placeholder="Enter Email" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-email"></i></span>
                                        {getFormError.email ?
                                            <div className="help-info" style={formError}>{getFormError.email[0]}</div>
                                            : null
                                        }
                                    </div>
                                    {/**Input a dropdown with country codes and selected countries */}
                                    <div className="input-group">
                                        <input type="text" name="phone" onChange={handleFormChange} className="form-control" placeholder="Enter Phone Number (*)" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-phone"></i></span>
                                        {getFormError.phone ?
                                            <div className="help-info" style={formError}>{getFormError.phone[0]}</div>
                                            : null
                                        }
                                    </div>
                                    <div className="input-group">
                                        <input type="text" name="referral_code" onChange={handleFormChange} className="form-control" placeholder="Enter Refferal Code" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-phone"></i></span>
                                        {getFormError.referral_code ?
                                            <div className="help-info" style={formError}>{getFormError.referral_code[0]}</div>
                                            : null
                                        }
                                    </div>
                                    <div className="input-group">
                                        <input type="text" name="id_number" onChange={handleFormChange} className="form-control" placeholder="Enter ID Number (*)" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-phone"></i></span>
                                        {getFormError.id_number ?
                                            <div className="help-info" style={formError}>{getFormError.id_number[0]}</div>
                                            : null
                                        }
                                    </div>
                                    <div className="input-group">
                                        <input type="file" className="form-control" name="picture" />
                                    </div>
                                    {/*TODO position a map that when clicked can retrieve lati and longi of the given location*/}
                                    <div className="input-group">
                                        <input type="number" name="latitude" onChange={handleFormChange} className="form-control" placeholder="Enter Latitude" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-phone"></i></span>
                                    </div>
                                    <div className="input-group">
                                        <input type="number" name="longitude" onChange={handleFormChange} className="form-control" placeholder="Enter Longitude" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-phone"></i></span>
                                    </div>

                                    <div className="input-group">
                                        <input type="password" name="password" onChange={handleFormChange} placeholder="Password" className="form-control" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-lock"></i></span>
                                    </div>
                                    <div className="input-group">
                                        <input type="password" onChange={handleFormChange} name="confirm_password" placeholder="Confirm Password" className="form-control" />
                                        <span className="input-group-addon"><i className="zmdi zmdi-lock"></i></span>
                                    </div>
                                    <div className="footer">
                                        <button type="submit" className="btn btn-primary btn-round btn-block">SIGN UP</button>
                                    </div>
                                </form>
                                <Link className="link" to="/login">You already have a membership?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;