import { TOKEN_KEY } from "../../components/Constants";

function Logout() {

    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
        window.location.href = "/login";
    } else {
        localStorage.removeItem(TOKEN_KEY);
        window.location.href = "/login";
    }



    return (
        <div></div>
    );
}

export default Logout;