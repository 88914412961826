//Core components
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

//Project components
import Alert from "../components/Alert";
import UsersCreate from "../components/forms/UsersCreate";

//External css and js
import 'datatables.net-dt';
import "../components/css/multi-select.css";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

//Global constants
import {
    TOKEN,
    GET_USERS,
    TOKEN_KEY,
    GET_USER_ROLE,
} from "../components/Constants";
import ActivateUser from "../components/forms/ActivateUser";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";

function Users() {

    const [alert, setAlert] = useState("");
    const [getUsers, setUsers] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [createUserStatus, setcreateUserStatus] = useState(false);
    const [createUserResponse, setcreateUserResponse] = useState("");
    const [activateUserDialog, setActivateUserDialog] = useState({ state: false });

    //Check if user is logged in
    const token = localStorage.getItem(TOKEN_KEY);

    useEffect(() => {
        //Get loan form registered in the app
        // const axiosInstance = axios.create({
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //         'Content-Type': 'application/json',
        //     },
        // });

        if (!token) {
            window.location.href = "/login";
        }

        axios.get(GET_USERS)
            .then((response) => {
                setUsers(response.data);
                //console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const openModal = () => {
        setShowModal(true);
        //console.log("Cliked " + showModal);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const setAlertState = (stateBool) => {
        setcreateUserStatus(stateBool);
    };

    const setAlertMessageFn = (alertMessage) => {
        setcreateUserResponse(alertMessage);
    };

    const activetUser = (userState, user) => {
        setActivateUserDialog({ state: true, action: userState, user: user });
    }

    const closeUserActivateDialog = () => {
        setActivateUserDialog({ state: false });
    }

    const activateUserAlert = (dial) => {
        setAlert({ state: dial.status, message: dial.message });
    }

    const getUsersSection = (roleSearch) => {

        axios.get(GET_USER_ROLE + roleSearch)
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    const tableScroll = { overflowX: 'auto', };

    return (
        <div>
            <Nav />
            <Sidebar activeTab="users" />
            <section className="content inbox">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-5 col-md-5 col-sm-12">
                                <h2>Users</h2>
                                <ul className="breadcrumb ">
                                    <li className="breadcrumb-item"><a href="index.html"><i className="zmdi zmdi-home"></i></a></li>
                                    <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Users</li>
                                </ul>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="input-group m-b-0">
                                    <input type="text" className="form-control" placeholder="Search..." />
                                    <span className="input-group-addon"><i className="zmdi zmdi-search"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className="row clearfix card">
                            {createUserStatus ? <Alert alertState={createUserStatus} message={createUserResponse} /> : null}
                            {alert ? <Alert alertState={alert.state} message={alert.message} /> : null}
                            {activateUserDialog.state ? <ActivateUser actionPoint={activateUserDialog.action} setUserAlert={activateUserAlert} closeModalBtn={closeUserActivateDialog} user={activateUserDialog.user} /> : null}


                            <div className="col-md-12 col-lg-12" style={tableScroll}>
                                <div class="header">
                                    <h2><strong>Users</strong></h2>
                                    <button className="btn btn-primary btn-round" onClick={openModal}>Create</button>
                                    <ul class="header-dropdown">
                                        <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i class="zmdi zmdi-more"></i> </a>
                                            <ul class="dropdown-menu dropdown-menu-right" style={{ padding: 10, cursor: "pointer" }}>
                                                <li onClick={() => getUsersSection('all')}>All users</li>
                                                <li onClick={() => getUsersSection('customer')}>Customers</li>
                                                <li onClick={() => getUsersSection('serviceProvider')}>Service Provider</li>
                                                <li onClick={() => getUsersSection('agent')}>Agents</li>
                                                <li onClick={() => getUsersSection('admin')}>Admin</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="body">
                                    {showModal ? (
                                        <UsersCreate closeModalBtn={closeModal} setAlert={setAlertState} setAlertMessage={setAlertMessageFn} />
                                    ) : null}
                                    <table className="table table-bordered table-striped table-hover dataTable js-exportable">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Phone number</th>
                                                <th>Email</th>
                                                <th>Id Number</th>
                                                <th>Referral code</th>
                                                <th>User role</th>
                                                <th>Suburb</th>
                                                <th>Active</th>
                                                <th>Date joined</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>Name</th>
                                                <th>Phone number</th>
                                                <th>Email</th>
                                                <th>Id Number</th>
                                                <th>Referral code</th>
                                                <th>User role</th>
                                                <th>Suburb</th>
                                                <th>Active</th>
                                                <th>Date joined</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {getUsers ? (
                                                getUsers.map((user) => (

                                                    <tr>
                                                        <td><Link to={"/profile/" + user.id}>{user.name}</Link></td>
                                                        <td>{user.phonenumber ?? "N/A"}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.id_number}</td>
                                                        <td>{user.referral_code}</td>
                                                        <td>{user.user_role ? user.user_role[0] : "N/A"}</td>
                                                        <td>{user.suburb}</td>
                                                        <td>
                                                            {user.active ? "Active" : "Inactive"}
                                                            <span>
                                                                {user.active ?
                                                                    <i class="zmdi zmdi-close" onClick={() => { activetUser("deactivate", user) }} style={{ fontSize: 20, marginLeft: 19, cursor: "pointer" }}></i>
                                                                    :
                                                                    <i class="zmdi zmdi-check" onClick={() => { activetUser("activate", user) }} style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}></i>
                                                                }
                                                            </span>
                                                        </td>
                                                        <td>{user.created_at}</td>
                                                    </tr>

                                                ))
                                            ) :

                                                <tr>
                                                    <td colSpan={6} align="center">No data to display</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Users;